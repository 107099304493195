const minute = 1000 * 60

export const initialTime = 20 * minute

export const timeAfterExtending = 10 * minute

export const timeForCheckout = 20 * minute

export const maxExtensions = 1

export const skew = 2 * 1000

export const showExtensionAt = 2 * minute
