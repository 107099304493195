import React from 'react'
import { Button as CButton } from '@rmwc/button'

import '@rmwc/button/styles'
import './Button.scss'

function Button ({ className = '', label, icon, unelevated, outlined, dense, disabled, onClick }) {
  const style = `nbutton ${className}`.trim()
  return (
    <CButton
      className={style}
      {...{ label, icon, unelevated, outlined, dense, onClick }}
    />
  )
}

export default Button
