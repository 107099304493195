import React, { useState, useEffect, useContext, useRef } from 'react'
import { HeaderContext } from '../App'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Checkbox from './Checkbox'
import SVGIcon from '~/components/SVGIcon'
import ErrorMessage from '~/components/ErrorMessage'
import Button from '~/components/Button'
import Paypal from '~/components/Paypal'
import http, { wrapButtonToggle } from '~/http'
import useShowLoader from './Loader'

// --vars
let wedOeSectionIds = []

// --Components
function CartPage (props) {
  const { cart, students } = props
  const [globalError, setGlobalError] = useState(null)
  const [paypalButtonDisabled, setPaypalButtonDisabled] = useState(false)
  const [paypalButtonLoaded, setPaypalButtonLoaded] = useState(false)
  const [overlay, setOverlay] = useState(false)
  const [captureId, setCaptureId] = useState(null)
  const [mailPermission, setMailPermission] = useState(true)

  const mailPermissionRef = useRef(mailPermission)

  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'My Cart' })
  }, [])

  useEffect(() => {
    mailPermissionRef.current = mailPermission
  }, [mailPermission])

  if (captureId != null) {
    props.history.push(`/receipt/${captureId}`)
    window.scrollTo(0, 0)
    props.dispatch({
      type: 'RESET_STATE',
      data: {}
    })
  }

  const byStudent = cart.reservations.reduce((acc, reservation) => {
    if (acc[reservation.studentPublicId] == null) {
      acc[reservation.studentPublicId] = []
    }
    acc[reservation.studentPublicId].push(reservation)
    return acc
  }, {})

  const showLocationsWarning = cart.reservationsByType.SQ.length > 0 && Object.keys(cart.sqCampuses).length > 1

  wedOeSectionIds = cart.reservationsByType.WEDOE.length > 0
    ? cart.reservationsByType.WEDOE.reduce((acc, course) => {
      acc.push(course.section.id)
      return acc
    }, [])
    : []

  const sectionPrerequisites = cart.reservationsByType.WEDOE
    .filter(reservation => reservation.section.prerequisite != null)
    .reduce((acc, res) => {
      const { section, courseName } = res
      const title = courseName.trim()
      if (acc[title] == null) {
        acc[title] = { title, prerequisite: section.prerequisite }
      }
      return acc
    }, {})

  const sectionCeEligibility = cart.reservationsByType.WEDOE
    .filter(reservation => reservation.section.isCeEligible)
    .reduce((acc, res) => {
      const { courseName } = res
      const title = courseName.trim()
      if (acc[title] == null) {
        acc[title] = { title }
      }
      return acc
    }, {})

  return (
    <div className='page cart'>

      {overlay && (
        <>
          <div className='page-header'>
            <div className='contents row'>
              Processing
            </div>
          </div>

          <div className='page-body'>
            <div className='contents'>
              <ErrorMessage notice message='Please wait while we process your order...' />
            </div>
          </div>
        </>
      )}

      {cart.reservations.length > 0 && !overlay &&
        <div className='page-header'>
          <div className='contents row'>
            <Button
              label='Home'
              className='top-search'
              outlined
              onClick={() => props.history.push('/')}
            />
          </div>
        </div>}

      <div className='page-body' style={{ display: overlay ? 'none' : undefined }}>
        {globalError && <ErrorMessage message={globalError} />}
        {showLocationsWarning && <ErrorMessage warning message='Warning: Your cart contains courses at multiple locations' />}

        {Object.keys(byStudent).map(studentPublicId => {
          const reservations = byStudent[studentPublicId]
          const student = students.find(s => s.publicId === studentPublicId)

          return (
            <div className='student' key={studentPublicId}>
              <Link to='/students' onClick={() => window.scrollTo(0, 0)} className='student-name'>
                {reservations[0].studentName}
              </Link>

              {reservations.map((reservation, idx) => {
                return <Reservation {...{ reservation, showLocationsWarning, setPaypalButtonDisabled, parentProps: props, setGlobalError, student }} key={idx} />
              })}
            </div>
          )
        })}

        {cart.reservations.length > 0
          ? (
            <>
              <div className='total'>{`Total: $${cart.total.toFixed(2)}`}</div>
              <div className='row mail-permission'>
                <Checkbox
                  nativeControlId='mail-permission'
                  checked={mailPermission}
                  onChange={(evt) => {
                    setMailPermission(evt.target.checked)
                  }}
                  label={'I\'d like to stay connected with Francis Tuttle via email'}
                />
              </div>
              <div className='discounts'>
                Discounts may be available for people in the following categories: Military/Veteran, FT Board, FT Foundation Board, Employee, Employee Family, Retired Employee, Senior Citizen, Industry Advisor, and Sending School Partner.
                If you fit into one of these, please contact our enrollment offices at 405-717-4900 to process your enrollment.
              </div>
              {
                Object.keys(sectionPrerequisites).length > 0 && (
                  <div className='prerequisites'>
                      The Classes below contain pre-requisites, some of which are mandated by governing agencies.
                      Please make sure you meet these requirements.
                      If you do enroll, there is a chance you could be removed from the class for not meeting governed mandatory requirements.
                      In this case, your money will be refunded through our normal refund process, which can take up to 10 business days.
                      If you have further questions regarding pre-requisites for this class please call 405-717-4900
                    {
                      Object.keys(sectionPrerequisites)
                        .map((key, idx) => {
                          const { title, prerequisite } = sectionPrerequisites[key]
                          return <div key={idx}>{title} ({prerequisite})</div>
                        })
                    }
                  </div>
                )
              }
              {
                Object.keys(sectionCeEligibility).length > 0 && (
                  <div className='continuing-ed'>
                      The Classes below are eligible for continuing education credit.
                      A license is not required to take them.
                      However, failure to provide your information could result in a delay in, or not receiving credit.
                    {
                      Object.keys(sectionCeEligibility)
                        .map((key, idx) => {
                          const { title } = sectionCeEligibility[key]
                          return <div key={idx}>{title}</div>
                        })
                    }
                  </div>
                )
              }
              <div className='terms-acknowledge'>
                  By clicking any of the payment options below, I agree to the <Link to='/terms'>Terms of Use</Link>.
              </div>
              <div className='payment-placeholder'>
                {!paypalButtonLoaded && globalError == null && <span id='paypal-loading'>Loading PayPal, please wait...</span>}

                {!paypalButtonDisabled && (
                  <Paypal
                    globalError={globalError}
                    setGlobalError={setGlobalError}
                    setPaypalButtonDisabled={setPaypalButtonDisabled}
                    setPaypalButtonLoaded={setPaypalButtonLoaded}
                    mailPermissionRef={mailPermissionRef}
                    setOverlay={setOverlay}
                    setCaptureId={setCaptureId}
                  />
                )}
              </div>
            </>
          ) : (
            <div className='empty-cart row'>
              {students.length === 0 ? (
                <>
                  <div>Your cart is empty, let's start by registering a student.</div>
                  <Button
                    label='Add Student'
                    icon='person_add'
                    unelevated
                    onClick={() => props.history.push('/addstudent')}
                  />
                </>
              ) : (
                <>
                  <div>Your cart is empty, let's start down a new path to learning.</div>
                  <Button
                    label='Home'
                    unelevated
                    onClick={() => props.history.push('/')}
                  />
                </>
              )}
            </div>
          )}
      </div>
    </div>
  )
}

function Reservation ({ reservation, showLocationsWarning, setPaypalButtonDisabled, parentProps, setGlobalError, student }) {
  const isWedOe = wedOeSectionIds.includes(reservation.section.id)

  const courseUrl = isWedOe
    ? `/ll-courses?q=${reservation.courseName}`
    : `/sq-courses?q=${reservation.courseName}`

  const showLoader = useShowLoader()

  return (
    <div className='reservation' key={reservation.publicId}>
      <Link to={courseUrl} onClick={() => window.scrollTo(0, 0)}>
        {`$${reservation.section.price.toFixed(2)} -`}
        <SVGIcon icon='link' viewBox='0 0 24 24' />
        <span className='course-name'>{reservation.courseName}</span>
      </Link>
      <div className='row'>
        <span className='cell'>{reservation.section.startDate}</span>
        <span className='cell'>{reservation.section.startTime}</span>
      </div>
      <div className='row'>
        <span className={`cell ${showLocationsWarning ? 'locations-warning' : ''}`}>
          {`${reservation.section.campus} Campus`}
        </span>
        <Button
          label='Remove'
          icon='delete'
          dense
          onClick={
            wrapButtonToggle(
              setPaypalButtonDisabled,
              async () => {
                showLoader(true)
                await removeFromCart(parentProps, setGlobalError, reservation, student)
                showLoader(false)
              })
          }
        />
      </div>
    </div>
  )
}

// --functions
async function removeFromCart (props, setGlobalError, reservation, student) {
  const payload = {
    cartPublicId: props.cart.publicId,
    reservationPublicId: reservation.publicId,
    studentPublicId: student.publicId
  }

  try {
    const response = await http(setGlobalError, http => http.post('/api/SelfService/Cart/RemoveEnrollment', payload))
    props.dispatch({
      type: 'REMOVE_RESERVATION',
      data: {
        cart: response.data.cart,
        reservations: response.data.reservations
      }
    })
  } catch (err) {
    window.scrollTo(0, 0)
  }
}

// --mappings
export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(CartPage)
