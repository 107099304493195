import React, { createContext, useContext, useEffect, useState } from 'react'
import { request } from 'graphql-request'

const query = `
query {
  weeks {
    id
    name
    startDate
    endDate
  }
  timeSlots {
    id
    name
    startTime
    endTime
  }
  locations {
    name
    sqEligible
  }
  season {
    id
    name
    showDate
    hideDate
    registrationDate
    registrationTime
    registrationStarts
  }
  courseCategories {
    name
    description
  }
}
`

const RefdataContext = createContext()

export default function Provider ({ children }) {
  const [contextData, setContextData] = useState({
    weeks: [],
    timeSlots: [],
    locations: [],
    seasonInfo: {},
    courseCategories: [],
    loaded: false
  })
  useEffect(() => {
    (async () => {
      try {
        const response = await request('/api/public/graphql', query)
        const { locations, ...rest } = response
        const allowedLocations = locations.filter(x => x.name !== 'Cross Timbers')
        setContextData({ locations: allowedLocations, ...rest })
      } catch (e) {
        // FIXME: eat error for now
      }
    })()
  }, [])

  return (
    <RefdataContext.Provider value={contextData}>
      {children}
    </RefdataContext.Provider>
  )
}

export function FakeProvider ({ children, weeks, timeSlots }) {
  const contextData = { weeks, timeSlots }
  return (
    <RefdataContext.Provider value={contextData}>
      {children}
    </RefdataContext.Provider>
  )
}

export function useSeasonInfo () {
  const { season } = useContext(RefdataContext)

  return season
}

export function useWeeks () {
  const { weeks } = useContext(RefdataContext)

  return weeks
}

export function useTimeSlots () {
  const { timeSlots } = useContext(RefdataContext)

  return timeSlots
}

export function useLocations () {
  const { locations } = useContext(RefdataContext)

  return locations
}

export function useCourseCategories () {
  const { courseCategories } = useContext(RefdataContext)

  return courseCategories
}

export function useWeek (id) {
  const weeks = useWeeks()

  if (id == null) {
    return null
  }

  return weeks.find(x => x.id === id)
}

export function useTimeSlot (id) {
  const timeSlots = useTimeSlots()

  if (id == null) {
    return null
  }

  return timeSlots.find(x => x.id === id)
}
