import safeAssign from '~/helpers/safeAssign'

export const initialState = {
  address1: '',
  address2: '',
  city: '',
  state: 'OK',
  zipCode: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'DEFAULT_ADDRESS':
      return safeAssign(state, action.data)
  }

  return state
}
