import bowser from 'bowser'
const browser = bowser.getParser(window.navigator.userAgent)
const name = browser.getBrowserName()

function isOneOf (hostnames) {
  return hostnames.indexOf(window.location.hostname) >= 0
}

function containsOneOf (hostnames) {
  const hostname = window.location.hostname
  for (const h of hostnames) {
    if (hostname.indexOf(h) >= 0) {
      return true
    }
  }
  return false
}

const siteIsLive = true

function getEnv () {
  if (isOneOf([
    'prod.ftsis.francistuttle.edu',
    'ftsis.francistuttle.edu'
  ])) {
    return {
      NAME: 'prod',
      IS_LIVE: siteIsLive,
      RAYGUN_API_KEY: import.meta.env.VITE_PROD_RAYGUN_API_KEY,
      PAYPAL_CLIENT_ID: siteIsLive ? import.meta.env.VITE_PAYPAL_PROD_CLIENT_ID : '',
      RECAPTCHA_KEY: import.meta.env.VITE_RECAPTCHA_KEY,
      BROWSER: name
    }
  } else if (isOneOf([
    'prod.public.fttc.asemio.net'
  ])) {
    return {
      NAME: 'prod',
      IS_LIVE: siteIsLive,
      RAYGUN_API_KEY: import.meta.env.VITE_PROD_RAYGUN_API_KEY,
      PAYPAL_CLIENT_ID: import.meta.env.VITE_PAYPAL_PROD_CLIENT_ID,
      RECAPTCHA_KEY: import.meta.env.VITE_RECAPTCHA_KEY,
      BROWSER: name
    }
  } else if (isOneOf([
    'staging.public.fttc.asemio.net',
    'staging.ftsis.francistuttle.edu'
  ])) {
    return {
      NAME: 'staging',
      IS_LIVE: siteIsLive,
      RAYGUN_API_KEY: import.meta.env.VITE_STAGING_RAYGUN_API_KEY,
      PAYPAL_CLIENT_ID: siteIsLive ? import.meta.env.VITE_PAYPAL_SANDBOX_CLIENT_ID : '',
      RECAPTCHA_KEY: import.meta.env.VITE_RECAPTCHA_KEY,
      BROWSER: name
    }
  } else if (containsOneOf([
    '.public.fttc.asemio.net',
    '.ftsis.francistuttle.edu'
  ])) {
    return {
      NAME: 'branch',
      IS_LIVE: true,
      RAYGUN_API_KEY: import.meta.env.VITE_BRANCH_RAYGUN_API_KEY,
      PAYPAL_CLIENT_ID: import.meta.env.VITE_PAYPAL_SANDBOX_CLIENT_ID,
      RECAPTCHA_KEY: import.meta.env.VITE_RECAPTCHA_KEY,
      BROWSER: name
    }
  } else {
    return {
      NAME: 'development',
      IS_LIVE: true,
      RAYGUN_API_KEY: import.meta.env.VITE_RAYGUN_API_KEY,
      PAYPAL_CLIENT_ID: import.meta.env.VITE_PAYPAL_SANDBOX_CLIENT_ID,
      RECAPTCHA_KEY: import.meta.env.VITE_RECAPTCHA_LOCAL_KEY,
      BROWSER: name
    }
  }
}

export default getEnv()
