import React from 'react'
import {
  Card as NCard,
  CardPrimaryAction as NCardPrimaryAction,
  CardActions as NCardActions,
  CardActionButtons as NCardActionButtons,
  CardActionButton as NCardActionButton
} from '@rmwc/card'

import '@rmwc/card/styles'
import './Card.scss'

// --vars

// --components
function Card ({ className = '', ...rest }) {
  const style = `ncard ${className}`.trim()
  return <NCard className={style} {...rest} />
}
export function CardPrimaryAction ({ className = '', ...rest }) {
  const style = `ncard-action ${className}`.trim()
  return <NCardPrimaryAction className={style} {...rest} />
}
export function CardActions ({ className = '', ...rest }) {
  const style = `ncard-action ${className}`.trim()
  return <NCardActions className={style} {...rest} />
}
export function CardActionButtons ({ className = '', ...rest }) {
  const style = `ncard-action-buttons ${className}`.trim()
  return <NCardActionButtons className={style} {...rest} />
}
export function CardActionButton ({ className = '', ...rest }) {
  const style = `ncard-action-button ${className}`.trim()
  return <NCardActionButton className={style} {...rest} />
}

// --functions

// --mappings
export default Card
