export default `
query ($searchText: String, $locations: [String!], $courseCategories: [CourseCategoryType!]) {
  wedOeCourses(searchText: $searchText, locations: $locations, courseCategories: $courseCategories) {
    title
    description
    id
    sections {
      id
      availableCapacity
      location
      maxCapacity
      courseSectionNumber
      startDate
      endDate
      startTime
      endTime
      meetingPattern
      notEnrollableReason
      price
      priceDisplay
      tuitionDisplay
      suppliesDisplay
      bookDisplay
      testDisplay
      prerequisite
      scheduleNotes
      isCeEligible
      ceEligibility
    }
  }
}
`
