import React from 'react'
import { Icon as NIcon } from '@rmwc/icon'

// --vars

// --components
function Icon ({ ...rest }) {
  return (
    <NIcon {...{ ...rest }} />
  )
}

// --functions

// --mappings
export default Icon
