import safeAssign from '~/helpers/safeAssign'

export const initialState = {
  firstName: '',
  lastName: '',
  relationship: '',
  email: '',
  primaryPhone: {
    number: '',
    type: 'Mobile'
  },
  secondaryPhone: {
    number: '',
    type: ''
  },
  textPermission: false,
  optOutOfTextMessages: false,
  isPrimaryAdult: false,
  isEmergencyContact: false,
  notes: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'DEFAULT_CONTACT':
      return safeAssign(state, action.data)
  }

  return state
}
