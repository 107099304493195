import env from '~/environment'
import { logRaygunError } from '~/logging'

function loadScript () {
  if (window.paypalLoader == null) {
    window.paypalLoader = new Promise(function (resolve, reject) {
      const script = document.createElement('script')
      script.src = `https://www.paypal.com/sdk/js?client-id=${env.PAYPAL_CLIENT_ID}`
      script.onload = resolve
      script.onerror = reject
      script.type = 'text/javascript'
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }

  return window.paypalLoader
}

var orders = {}

export async function renderButtons (divId, cartPublicId, setPaypalButtonLoaded, setGlobalError, order, doPreflight, doComplete) {
  const container = document.createElement('div')
  container.setAttribute('id', `paypal-inner-${divId}`)
  const parent = document.getElementById(`paypal-parent-${divId}`)
  parent.appendChild(container)

  const payPalErrorRef = { error: null }
  const handleError = (err) => {
    window.scrollTo(0, 0)
    console.error(err)

    if (payPalErrorRef.error != null) {
      // Failure during Complete call
      setGlobalError(payPalErrorRef.error)
      if (payPalErrorRef.response != null) {
        logRaygunError(new Error(payPalErrorRef.response))
      } else {
        logRaygunError(new Error(payPalErrorRef.error))
      }
    } else {
      // Failure in PayPal widget
      setGlobalError('PayPal could not process the transaction, please try again or contact us at 405-717-4900')
      logRaygunError(err)
    }
  }

  try {
    await loadScript()
    setPaypalButtonLoaded(true)

    await window.paypal.Buttons({
      style: {
        size: 'responsive',
        shape: 'rect',
        color: 'blue',
        height: 30,
        label: 'paypal'
      },
      createOrder: async function (data, actions) {
        // console.log('CREATE ORDER', divId)
        await doPreflight()

        return actions.order.create(order)
      },
      onApprove: async function (data, actions) {
        // console.log('onApprove', divId)

        if (orders[cartPublicId] == null) {
          orders[cartPublicId] = true
          await doComplete(payPalErrorRef, data.orderID)
        }
      },
      onCancel: function () {
        // console.log('onCancel', divId)
      },
      onClose: function () {
        // console.log('onClose', divId)
      },
      onError: function (err) {
        handleError(err)
        // console.log('onError', divId)
      }
    }).render(`#paypal-inner-${divId}`)
  } catch (err) {
    // console.log('Global catch', divId)
    // Special case to ignore a false positive caused by unloading or closing the
    // PayPal window when it doesn't expect it. We don't control when the user
    // chooses to close the window and we also don't want to treat this
    // exception as an error. Side note, we unload the buttons/window whenever
    // the cart changes, for consistency and as an extra layer of security
    // against race conditions.
    if (err.message !== 'Window closed') {
      handleError(err)
    }
  }
}

export async function unloadButtons (divId) {
  // console.log('unload', divId)
  const element = document.getElementById(`paypal-inner-${divId}`)
  if (element != null) {
    element.parentNode.removeChild(element)
  }
}
