import React from 'react'
import { Checkbox as NCheckbox } from '@rmwc/checkbox'
import { ThemeProvider } from '@rmwc/theme'
import themeColors from '../constants/themeColors'

import '@rmwc/checkbox/styles'
import './Checkbox.scss'

// --vars
const options = { secondary: themeColors.primary, onPrimary: themeColors.primary, onSecondary: themeColors.primary }

// --components
function Checkbox ({ ...rest }) {
  return (
    <ThemeProvider options={options}>
      <NCheckbox
        className='ncheckbox'
        {...{ ...rest }}
      />
    </ThemeProvider>
  )
}

// --functions

// --mappings
export default Checkbox
