import React, { useState } from 'react'
import Card from '../../components/Card'
import SVGIcon from '~/components/SVGIcon'
import ErrorMessage from '~/components/ErrorMessage'
import Button from '~/components/Button'
import stripTags from '~/helpers/stripTags'

export default function SearchResultCard ({
  htmlId,
  description: originalDescription,
  title,
  warningMessage,
  errorMessage,
  selectedSection,
  setSelectedSection,
  children
}) {
  const [expanded, setExpanded] = useState(selectedSection != null)
  const [processedDescription] = useState(() => {
    const words = stripTags(originalDescription).split(' ').filter(w => w.length > 0)
    const alwaysVisible = words.reduce((acc, word, i) => {
      const lastWord = acc.words[acc.words.length - 1]
      if (acc.length > 100 && lastWord[lastWord.length - 1] === '.') {
        return acc
      }
      acc.words.push(word)
      acc.length += word.length
      return acc
    }, { words: [], length: 0 })

    return {
      short: alwaysVisible.words.join(' '),
      full: words.join(' '),
      needsExpand: alwaysVisible.words.length < words.length
    }
  })
  const expandable = processedDescription.needsExpand
  const { description, expandText } = expanded ? {
    description: processedDescription.full,
    expandText: 'Read less'
  } : {
    description: processedDescription.short,
    expandText: 'Read more'
  }
  return (
    <div className='result'>
      <Card className='card' id={htmlId}>
        <div className='item row title'>
          <span className='cell'>{title}</span>
          {selectedSection != null &&
            <Button
              dense
              label='Close'
              icon='close'
              onClick={() => setSelectedSection(null)}
            />}
        </div>
        <div className={`item description ${expandable ? 'expand' : ''}`} onClick={() => setExpanded(!expanded)}>
          {description}
          {expandable && <span className='more'>{expandText}<SVGIcon icon='more' className='more-icon' /></span>}
        </div>
        {warningMessage &&
          <ErrorMessage warning message={warningMessage} />}
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {children}
      </Card>
      <div className='scroll-to-top' onClick={() => window.scrollTo(0, 0)}>Scroll to top</div>
    </div>
  )
}
