import React from 'react'

export default function ErrorList (props) {
  const { errors } = props
  return (
    <div className='error-list'>
      <ul>
        {Object.keys(errors).map(field =>
          <li key={field}>
            <span className='message'>{errors[field]}</span>
          </li>
        )}
      </ul>
    </div>
  )
}
