import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../App'
import { connect } from 'react-redux'
import Checkbox from './Checkbox'
import recaptcha from '~/helpers/loadRecaptcha'
import http, { preparePayload, formatErrors, wrapButtonToggle } from '~/http'
import { initialState as blankContact } from '~/reducers/defaultContact'
import states from '~/constants/states'
import ErrorMessage from '~/components/ErrorMessage'
import ErrorList from '~/components/ErrorList'
import SVGIcon from '~/components/SVGIcon'
import Button from '~/components/Button'
import Select from '~/components/Select'
import TextField from './TextField'
import DatePicker from '~/components/DatePicker'
import { ageToday } from '~/helpers/dateAndTime'
import { formatPhoneNumber } from '~/helpers/phoneNumber'

// --vars

const relationships = {
  Parent: 'Parent',
  Guardian: 'Guardian',
  Spouse: 'Spouse',
  Companion: 'Companion',
  Grandparent: 'Grandparent',
  Stepparent: 'Stepparent',
  Sibling: 'Sibling',
  Child: 'Child',
  Stepchild: 'Stepchild',
  Grandchild: 'Grandchild',
  Friend: 'Friend',
  Other: 'Other'
}

const genders = {
  Female: 'Female',
  Male: 'Male',
  Other: 'Other'
}

const phoneTypes = {
  Mobile: 'Mobile',
  Work: 'Work',
  Residential: 'Residential'
}

const raceList = [
  'American Indian/Alaskan Native',
  'Asian',
  'Black or African American',
  'Hawaiian/Pacific Islander',
  'White',
  'Middle Eastern or North African',
  'Unknown'
]

const makeOptions = obj => Object.keys(obj).reduce((acc, x) => {
  acc.push({ value: x, label: obj[x] })
  return acc
}, [])

const stateOptions = makeOptions(states)
const relationshipOptions = makeOptions(relationships)
const genderOptions = makeOptions(genders)
const phoneTypeOptions = makeOptions(phoneTypes)

// --components
function AddStudentPage (props) {
  const [globalError, setGlobalError] = useState(null)
  const [fieldErrors, setFieldErrors] = useState({})
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [gender, setGender] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [address1, setAddress1] = useState(props.defaultAddress.address1)
  const [address2, setAddress2] = useState(props.defaultAddress.address2)
  const [city, setCity] = useState(props.defaultAddress.city)
  const [state, setState] = useState(props.defaultAddress.state)
  const [zipCode, setZipCode] = useState(props.defaultAddress.zipCode)
  const [hasAttendedBefore, setHasAttendedBefore] = useState(false)
  const [notes, setNotes] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneType, setPhoneType] = useState('')
  const [optOutOfTextMessages, setOptOutOfTextMessages] = useState(false)
  const [email, setEmail] = useState('')
  const [isAdult, setIsAdult] = useState(false)
  const [isWedIndustryStudent, setIsWedIndustryStudent] = useState(false)
  const [educationLevel, setEducationLevel] = useState('')
  const [educationLevels, setEducationLevels] = useState([])
  const [races, setRaces] = useState([])
  const [isHispanicLatino, setIsHispanicLatino] = useState(false)
  const [language, setLanguage] = useState(undefined)
  const [languageOptions, setLanguageOptions] = useState([])
  const [sectionNumber, setSectionNumber] = useState('')
  const [companySponsorshipInfo, setCompanySponsorshipInfo] = useState({
    companyGaveTimeOff: false,
    companyIsPaying: false,
    companyWillReimburse: false,
    selfEmployed: false,
    companyName: '',
    companyAddress: '',
    companyCity: '',
    companyState: '',
    companyZipCode: '',
    supervisorName: '',
    supervisorEmail: '',
    supervisorPhoneNumber: ''
  })
  const [contacts, setContacts] = useState([Object.assign({}, props.defaultContact)])

  const updateContact = (index, newValue) => {
    const newContacts = contacts.slice(0)
    newContacts[index] = newValue
    setContacts(newContacts)
  }
  const addContact = () => {
    const newContacts = contacts.slice(0)
    newContacts.push(Object.assign({}, blankContact))
    setContacts(newContacts)
  }
  const removeContact = (index) => {
    const newContacts = contacts.slice(0)
    newContacts.splice(index, 1)
    setContacts(newContacts)
  }

  const getStudent = () => ({
    firstName,
    middleName,
    lastName,
    gender,
    birthDate,
    address1,
    address2,
    city,
    state,
    zipCode,
    phoneNumber,
    phoneType,
    optOutOfTextMessages,
    email,
    hasAttendedBefore,
    notes,
    contacts,
    language,
    educationLevel,
    races,
    isHispanicLatino,
    sectionNumber,
    isWedIndustryStudent
  })

  const getPersonOptionData = async (isWedIndustryParam) => {
    const response = await http(setGlobalError, http => http.get('/api/SelfService/Students/GetStudentOptionData'))
    const educationLevelOptions = response.data.educationLevels.map(x => {
      return { label: x.name, value: x.id }
    })
    const languages = response.data.languages.map(x => {
      return { label: x.name, value: x.id }
    })

    if (isWedIndustryParam) {
      // Wed Industry Students will see a different order to the education level list.
      // Remove all education levels below high school and add them to the end.
      educationLevelOptions.push(educationLevelOptions.splice(educationLevelOptions
        .findIndex(x => x.label === 'Middle School, 6th Grade'), 1)[0])
      educationLevelOptions.push(educationLevelOptions.splice(educationLevelOptions
        .findIndex(x => x.label === 'Middle School, 7th Grade'), 1)[0])
      educationLevelOptions.push(educationLevelOptions.splice(educationLevelOptions
        .findIndex(x => x.label === 'Middle School, 8th Grade'), 1)[0])
      educationLevelOptions.push(educationLevelOptions.splice(educationLevelOptions
        .findIndex(x => x.label === 'High School, 9th Grade'), 1)[0])
      educationLevelOptions.push(educationLevelOptions.splice(educationLevelOptions
        .findIndex(x => x.label === 'High School, 10th Grade'), 1)[0])
      educationLevelOptions.push(educationLevelOptions.splice(educationLevelOptions
        .findIndex(x => x.label === 'High School, 11th Grade'), 1)[0])
      educationLevelOptions.push(educationLevelOptions.splice(educationLevelOptions
        .findIndex(x => x.label === 'High School, 12th Grade'), 1)[0])

      // Switch Less than a high school diploma and high school graduate.
      const lessThanHighSchoolIndex = educationLevelOptions.findIndex(x => x.label === 'Less than a high school degree')
      const lessThanHighSchoolElem = educationLevelOptions[lessThanHighSchoolIndex]
      educationLevelOptions.splice(lessThanHighSchoolIndex, 1)

      const highSchoolGraduateIndex = educationLevelOptions.findIndex(x => x.label === 'High school graduate')
      const highSchoolGraduateElem = educationLevelOptions[highSchoolGraduateIndex]
      educationLevelOptions.splice(highSchoolGraduateIndex, 1)

      educationLevelOptions.unshift(highSchoolGraduateElem, lessThanHighSchoolElem)
    }

    setEducationLevels(educationLevelOptions)
    setLanguageOptions(languages)
  }

  useEffect(() => {
    if (birthDate.length === 10) {
      setIsAdult(ageToday(birthDate) >= 16)
    } else {
      setIsAdult(false)
    }

    const addStudentHash = window.location.hash
    const isWedIndustryParam = addStudentHash.includes('isWedIndustry=true')
    setIsWedIndustryStudent(isWedIndustryParam)

    getPersonOptionData(isWedIndustryParam)
  }, [birthDate])

  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Add Student' })
  }, [])

  return (
    <div className='page add-student'>

      <div className='page-header'>
        <div className='contents'>
            Enter Student Information
        </div>
      </div>

      <div className='page-body'>
        {globalError && <ErrorMessage message={globalError} />}
        {Object.keys(fieldErrors).length > 0 && <ErrorList errors={fieldErrors} />}

        {/*
          WED INDUSTRY SECTION - This section is only visible if the user scans a QR code with the isWedIndustry=true parameter or puts it in the URL
        */}
        {isWedIndustryStudent && (
          <div className='row'>
            <TextField
              className='width-100-percent'
              label='Class Section Number *'
              required
              invalid={fieldErrors['Section Number'] != null}
              value={sectionNumber}
              onChange={evt => setSectionNumber(evt.target.value)}
            />
          </div>
        )}

        <div className='row anchor-width-75'>
          <TextField
            label='First Name *'
            required
            invalid={fieldErrors['First Name'] != null}
            value={firstName}
            onChange={evt => setFirstName(evt.target.value)}
            fullwidth
          />
          <TextField
            className='width-75-percent'
            label='Middle Name'
            invalid={fieldErrors['Middle Name'] != null}
            value={middleName}
            onChange={evt => setMiddleName(evt.target.value)}
          />
        </div>

        <div className='row anchor-width-75'>
          <TextField
            label='Last Name *'
            required
            invalid={fieldErrors['Last Name'] != null}
            value={lastName}
            onChange={evt => setLastName(evt.target.value)}
            fullwidth
          />
          <Select
            className='width-100-percent'
            name='Gender *'
            required
            fieldErrors={fieldErrors}
            value={gender}
            options={genderOptions}
            setter={setGender}
          />
        </div>

        <div className='row anchor-width-50'>
          <DatePicker
            className='cell'
            name='Birth Date'
            label='DOB'
            required
            fieldErrors={fieldErrors}
            value={birthDate}
            setter={setBirthDate}
          />
          <Select
            className='width-100-percent'
            name='Highest Education Level'
            fieldErrors={fieldErrors}
            value={educationLevel}
            options={educationLevels}
            setter={setEducationLevel}
          />
        </div>

        <div className='row'>
          <h5>Race (check any that apply)</h5>
        </div>
        {
          raceList.map((race, idx) =>
            <div className='row' key={idx}>
              <Checkbox
                id={`${race}-${idx}`}
                label={race}
                checked={races.includes(race)}
                onChange={(evt) => {
                  const isChecked = races.includes(race)
                  const personRaces = [].concat(races)

                  if (isChecked) {
                    personRaces.splice(personRaces.indexOf(race), 1)
                  } else {
                    personRaces.push(race)
                  }

                  setRaces(personRaces.slice(0))
                }}
              />
            </div>
          )
        }

        <div className='row'>
          <h5>Hispanic / Latino</h5>
        </div>
        <div className='row'>
          <Checkbox
            id='latino'
            checked={isHispanicLatino}
            onChange={(evt) => setIsHispanicLatino(evt.target.checked)}
            label='Yes'
          />
        </div>

        <div className='row'>
          <h5>Language</h5>
        </div>
        <div className='row anchor-width-100'>
          <Select
            className='width-100-percent'
            name='Please Select Your Primary Language'
            fieldErrors={fieldErrors}
            value={language}
            options={languageOptions}
            setter={setLanguage}
          />
        </div>

        <div className='row'>
          <h5>Current address and phone number</h5>
        </div>

        <div className='row'>
          <Checkbox
            id='student-opt-out'
            checked={optOutOfTextMessages}
            onChange={(evt) => setOptOutOfTextMessages(evt.target.checked)}
            label='Opt out of Text Messages?'
          />
        </div>

        <div className='row'>
          <TextField
            className='cell'
            label={`Email${isAdult ? ' *' : ''}`}
            required={isAdult}
            invalid={fieldErrors.Email != null}
            value={email}
            onChange={evt => setEmail(evt.target.value)}
          />
        </div>

        <div className='row'>
          <TextField
            className='cell'
            label='Address 1 *'
            required
            invalid={fieldErrors.Address1 != null}
            value={address1}
            onChange={evt => setAddress1(evt.target.value)}
          />
        </div>

        <div className='row'>
          <TextField
            className='cell'
            label='Address 2'
            invalid={fieldErrors.Address2 != null}
            value={address2}
            onChange={evt => setAddress2(evt.target.value)}
          />
        </div>

        <div className='row'>
          <TextField
            className='cell'
            label='City *'
            required
            invalid={fieldErrors.City != null}
            value={city}
            onChange={evt => setCity(evt.target.value)}
          />
        </div>

        <div className='row anchor-width-100'>
          <Select
            className='width-100-percent'
            label='State *'
            required
            invalid={fieldErrors.State != null}
            value={state}
            options={stateOptions}
            setter={setState}
          />
          <TextField
            className='width-75-percent'
            label='Zip Code *'
            required
            invalid={fieldErrors['Zip Code'] != null}
            value={zipCode}
            onChange={evt => setZipCode(evt.target.value)}
          />
        </div>

        <div className='row anchor-width-75'>
          <TextField
            label={`Phone${isAdult ? ' *' : ''}`}
            required={isAdult}
            invalid={fieldErrors['Phone Number'] != null}
            value={phoneNumber}
            onChange={evt => setPhoneNumber(evt.target.value)}
            onBlur={(evt) => setPhoneNumber(formatPhoneNumber(evt.target.value))}
            fullwidth
          />
          <Select
            className='width-100-percent'
            name={`Phone Type${phoneNumber != null && phoneNumber !== '' ? ' *' : ''}`}
            required={phoneNumber !== ''}
            fieldErrors={fieldErrors}
            value={phoneType}
            options={phoneTypeOptions}
            setter={setPhoneType}
          />
        </div>

        <div className='row notice'>Please tell us about health concerns or allergies.</div>

        <div className='row'>
          <TextField
            className='cell'
            name='Notes'
            label='Notes'
            textarea
            invalid={fieldErrors.Notes != null}
            value={notes}
            onChange={evt => setNotes(evt.target.value)}
          />
        </div>

        <div className='row'>
          <Checkbox
            checked={hasAttendedBefore}
            onChange={(evt) => setHasAttendedBefore(evt.target.checked)}
            label='Please check the box if this student has attended Francis Tuttle classes before'
          />
        </div>
      </div>

      {contacts.map((contact, contactIndex) =>
        <ContactForm
          key={`contact-${contactIndex}`}
          fieldErrors={fieldErrors}
          contact={contact}
          updateContact={updateContact.bind(null, contactIndex)}
          removeContactLabel={contacts.length > 1 ? 'Remove' : 'Reset'}
          removeContact={
            contacts.length > 1
              ? removeContact.bind(null, contactIndex)
              : () => { updateContact(contactIndex, Object.assign({}, blankContact)); props.dispatch({ type: 'DEFAULT_CONTACT', data: blankContact }) }
          }
          contactIndex={contactIndex}
        />
      )}

      <div className='page-body centered'>
        <Button
          outlined
          icon='contact_phone'
          onClick={addContact}
          label='Add Another Contact'
        />
      </div>

      {isAdult &&
        <CompanySponsorshipForm
          companySponsorshipInfo={companySponsorshipInfo}
          setCompanySponsorshipInfo={setCompanySponsorshipInfo}
          fieldErrors={fieldErrors}
        />}

      <div className='page-body centered'>
        <Button
          unelevated
          disabled={saveButtonDisabled}
          label='Save and Continue'
          onClick={
            wrapButtonToggle(setSaveButtonDisabled, () => saveStudent(props, getStudent(), companySponsorshipInfo, setGlobalError, setFieldErrors))
          }
          theme={['primaryBg']}
        />
      </div>

    </div>
  )
}

function ContactForm (props) {
  const {
    fieldErrors,
    contact,
    contactIndex,
    updateContact,
    removeContact,
    removeContactLabel
  } = props
  const prefix = `Contact ${contactIndex + 1}`

  return (
    <div>
      <div className='page-header'>
        <div className='contents'>
          <span className='max'>Related Contact</span>
          <SVGIcon icon='trashcan' className='none' onClick={removeContact} />
          <span className='none' onClick={removeContact}>{removeContactLabel}</span>
        </div>
      </div>

      <div className='page-body'>

        {contactIndex === 0 && (
          <div className='contact-requirements notice'>
            Please designate at least one Emergency Contact (and a Primary Contact for minor children).
          </div>
        )}

        <div className='row'>
          <TextField
            className='cell'
            label='First Name *'
            required
            invalid={fieldErrors[`${prefix} First Name`] != null}
            value={contact.firstName}
            onChange={evt => updateContact(Object.assign(contact, { firstName: evt.target.value }))}
          />
          <TextField
            className='cell'
            label='Last Name *'
            required
            invalid={fieldErrors[`${prefix} Last Name`] != null}
            value={contact.lastName}
            onChange={evt => updateContact(Object.assign(contact, { lastName: evt.target.value }))}
          />
        </div>

        <div className='row'>
          <TextField
            className='cell'
            type='email'
            label='Email'
            invalid={fieldErrors[`${prefix} Email`] != null}
            value={contact.email}
            onChange={evt => updateContact(Object.assign(contact, { email: evt.target.value }))}
          />
        </div>

        <div className='row anchor-width-100'>
          <Select
            className='width-100-percent'
            name={`${prefix} Relationship`}
            label='Relationship *'
            required
            fieldErrors={fieldErrors}
            value={contact.relationship}
            options={relationshipOptions}
            onChange={evt => updateContact(Object.assign(contact, { relationship: evt.target.value }))}
          />
        </div>

        <div className='row anchor-width-75'>
          <TextField
            label='Primary Phone *'
            invalid={fieldErrors[`${prefix} Primary Phone`] != null}
            required
            value={contact.primaryPhone.number}
            onChange={evt => updateContact(Object.assign(contact, { primaryPhone: { ...contact.primaryPhone, number: evt.target.value } }))}
            onBlur={(evt) => updateContact(Object.assign(contact, { primaryPhone: { ...contact.primaryPhone, number: formatPhoneNumber(evt.target.value) } }))}
            fullwidth
          />
          <Select
            className='width-100-percent'
            name={`${prefix} Primary Phone Type`}
            label='Type *'
            required
            fieldErrors={fieldErrors}
            value={contact.primaryPhone.type}
            options={phoneTypeOptions}
            onChange={evt => { updateContact(Object.assign(contact, { primaryPhone: { ...contact.primaryPhone, type: evt.target.value } })) }}
          />
        </div>

        <div className='row anchor-width-75'>
          <TextField
            label='Secondary Phone'
            invalid={fieldErrors[`${prefix} Secondary Phone`] != null}
            value={contact.secondaryPhone.number}
            onChange={evt => { updateContact(Object.assign(contact, { secondaryPhone: { ...contact.secondaryPhone, number: evt.target.value } })) }}
            onBlur={(evt) => { updateContact(Object.assign(contact, { secondaryPhone: { ...contact.secondaryPhone, number: formatPhoneNumber(evt.target.value) } })) }}
            fullwidth
          />
          <Select
            className='width-100-percent'
            label={`Type${contact.secondaryPhone.number != null && contact.secondaryPhone.number !== '' ? ' *' : ''}`}
            fieldErrors={fieldErrors}
            value={contact.secondaryPhone.type}
            options={phoneTypeOptions}
            onChange={evt => { updateContact(Object.assign(contact, { secondaryPhone: { ...contact.secondaryPhone, type: evt.target.value } })) }}
          />
        </div>

        <div className='row'>
          <Checkbox
            id={`${prefix}-opt-out`}
            checked={contact.optOutOfTextMessages}
            onChange={(evt) => { updateContact(Object.assign(contact, { optOutOfTextMessages: evt.target.checked })) }}
            label='Opt out of Text Messages?'
          />
        </div>

        <div className='row'>
          <Checkbox
            id={`${prefix}-primary-adult`}
            checked={contact.isPrimaryAdult}
            onChange={(evt) => { updateContact(Object.assign(contact, { isPrimaryAdult: evt.target.checked })) }}
          >
            <>Primary Contact <span>(Will be contacted for enrollment-related issues for minor children)</span></>
          </Checkbox>

        </div>

        <div className='row'>
          <Checkbox
            id={`${prefix}-emergency-contact`}
            checked={contact.isEmergencyContact}
            onChange={(evt) => { updateContact(Object.assign(contact, { isEmergencyContact: evt.target.checked })) }}
            label='Emergency Contact'
          />
        </div>

        <div className='row'>
          <TextField
            name={`${prefix} Notes`}
            label='Notes'
            textarea
            invalid={fieldErrors[`${prefix} Notes`] != null}
            value={contact.notes}
            onChange={evt => { updateContact(Object.assign(contact, { notes: evt.target.value })) }}
          />
        </div>

      </div>
    </div>
  )
}

function CompanySponsorshipForm ({ companySponsorshipInfo, setCompanySponsorshipInfo, fieldErrors }) {
  const companySupported =
    companySponsorshipInfo.companyGaveTimeOff ||
    companySponsorshipInfo.companyIsPaying ||
    companySponsorshipInfo.companyWillReimburse

  return (
    <>
      <div className='page-header'>
        <div className='contents'>
            Enter Company Sponsorship Information
        </div>
      </div>

      <div className='page-body'>
        <div className='row'>
          <h4>Check any of the boxes below that apply to you:</h4>
        </div>

        <div className='row'>
          <Checkbox
            checked={companySponsorshipInfo.companyGaveTimeOff}
            invalid={fieldErrors.companyGaveTimeOff != null}
            onChange={(evt) => setCompanySponsorshipInfo({
              ...companySponsorshipInfo,
              takenForPersonalEnrichment: evt.target.checked ? false : companySponsorshipInfo.takenForPersonalEnrichment,
              companyGaveTimeOff: evt.target.checked
            })}
            label='My company gave me time off to take this class.'
          />
        </div>

        <div className='row'>
          <Checkbox
            checked={companySponsorshipInfo.companyIsPaying}
            invalid={fieldErrors.companyIsPaying != null}
            onChange={(evt) => setCompanySponsorshipInfo({
              ...companySponsorshipInfo,
              takenForPersonalEnrichment: evt.target.checked ? false : companySponsorshipInfo.takenForPersonalEnrichment,
              companyIsPaying: evt.target.checked
            })}
            label='My company is paying for this class.'
          />
        </div>

        <div className='row'>
          <Checkbox
            checked={companySponsorshipInfo.companyWillReimburse}
            invalid={fieldErrors.companyWillReimburse != null}
            onChange={(evt) => setCompanySponsorshipInfo({
              ...companySponsorshipInfo,
              takenForPersonalEnrichment: evt.target.checked ? false : companySponsorshipInfo.takenForPersonalEnrichment,
              companyWillReimburse: evt.target.checked
            })}
            label='I will be reimbursed by my company for attending this class.'
          />
        </div>

        <div className='row'>
          <Checkbox
            checked={!companySupported}
            onChange={(evt) => {
              setCompanySponsorshipInfo({
                ...companySponsorshipInfo,
                companyGaveTimeOff: evt.target.checked ? false : companySponsorshipInfo.companyGaveTimeOff,
                companyIsPaying: evt.target.checked ? false : companySponsorshipInfo.companyIsPaying,
                companyWillReimburse: evt.target.checked ? false : companySponsorshipInfo.companyHiredIntoAssociatedPosition
              })
            }}
            label='None of the above.'
          />
        </div>

        {companySupported &&
          <>
            <br />
            <br />

            <div className='row'>
              <TextField
                className='cell'
                label='Company Name *'
                value={companySponsorshipInfo.companyName}
                invalid={fieldErrors.companyName != null}
                onChange={evt => setCompanySponsorshipInfo({ ...companySponsorshipInfo, companyName: evt.target.value })}
                required={companySupported}
              />
            </div>

            <div className='row'>
              <TextField
                className='cell'
                label='Company Address *'
                value={companySponsorshipInfo.companyAddress}
                invalid={fieldErrors.companyAddress != null}
                onChange={evt => setCompanySponsorshipInfo({ ...companySponsorshipInfo, companyAddress: evt.target.value })}
                required={companySupported}
              />
            </div>

            <div className='row'>
              <TextField
                className='cell'
                label='City *'
                value={companySponsorshipInfo.companyCity}
                invalid={fieldErrors.companyCity != null}
                onChange={evt => setCompanySponsorshipInfo({ ...companySponsorshipInfo, companyCity: evt.target.value })}
                required={companySupported}
              />
            </div>

            <div className='row'>
              <Select
                className='cell-3'
                label='State *'
                value={companySponsorshipInfo.companyState}
                invalid={fieldErrors.companyState != null}
                options={stateOptions}
                setter={value => setCompanySponsorshipInfo({ ...companySponsorshipInfo, companyState: value })}
                required={companySupported}
              />
              <TextField
                className='cell-2'
                label='Zip Code *'
                value={companySponsorshipInfo.companyZipCode}
                invalid={fieldErrors.companyZipCode != null}
                onChange={evt => setCompanySponsorshipInfo({ ...companySponsorshipInfo, companyZipCode: evt.target.value })}
                required={companySupported}
              />
            </div>

            <div className='row'>
              <Checkbox
                checked={companySponsorshipInfo.selfEmployed}
                invalid={fieldErrors.selfEmployed != null}
                onChange={(evt) => setCompanySponsorshipInfo({
                  ...companySponsorshipInfo,
                  selfEmployed: evt.target.checked
                })}
                label='Are you self employed?'
              />
            </div>

            {!companySponsorshipInfo.selfEmployed &&
              <>
                <div className='row'>
                  <TextField
                    className='cell'
                    label="Supervisor's Name *"
                    value={companySponsorshipInfo.supervisorName}
                    invalid={fieldErrors.supervisorName != null}
                    onChange={evt => setCompanySponsorshipInfo({ ...companySponsorshipInfo, supervisorName: evt.target.value })}
                    required={companySupported && !companySponsorshipInfo.selfEmployed}
                  />
                </div>

                <div className='row'>
                  <TextField
                    className='cell'
                    label="Supervisor's Phone Number *"
                    value={companySponsorshipInfo.supervisorPhoneNumber}
                    invalid={fieldErrors.supervisorPhoneNumber != null}
                    onChange={evt => setCompanySponsorshipInfo({ ...companySponsorshipInfo, supervisorPhoneNumber: evt.target.value })}
                    onBlur={evt => setCompanySponsorshipInfo({ ...companySponsorshipInfo, supervisorPhoneNumber: formatPhoneNumber(evt.target.value) })}
                    required={companySupported && !companySponsorshipInfo.selfEmployed}
                  />

                  <TextField
                    className='cell'
                    label="Supervisor's Email *"
                    value={companySponsorshipInfo.supervisorEmail}
                    invalid={fieldErrors.supervisorEmail != null}
                    onChange={evt => setCompanySponsorshipInfo({ ...companySponsorshipInfo, supervisorEmail: evt.target.value })}
                    required={companySupported && !companySponsorshipInfo.selfEmployed}
                  />
                </div>
              </>}
          </>}
      </div>
    </>
  )
}

// --functions
async function saveStudent (props, student, companySponsorshipInfo, setGlobalError, setFieldErrors) {
  const token = await recaptcha('saveStudent')

  const payload = preparePayload({ ...student, ...companySponsorshipInfo })
  payload.contacts.forEach(contact => {
    if (contact.secondaryPhone.number == null && contact.secondaryPhone.type == null) {
      delete contact.secondaryPhone
    }
  })
  Object.assign(payload, { recaptchaToken: token })

  try {
    const response = await http(setGlobalError, http => http.post('/api/SelfService/Students', payload))
    student.publicId = response.data.publicId
    student.contacts.forEach((contact, i) => {
      contact.publicId = response.data.contacts[i]
    })
    student.companySponsorshipInfo = companySponsorshipInfo

    props.dispatch({
      type: 'ADD_STUDENT',
      data: student
    })
    props.dispatch({
      type: 'DEFAULT_ADDRESS',
      data: student
    })
    props.dispatch({
      type: 'DEFAULT_CONTACT',
      data: student.contacts[0]
    })
    setTimeout(() => {
      if (student.isWedIndustryStudent) {
        props.history.push('/students?isWedIndustry=true')
      } else {
        props.history.push('/students')
      }
    }, 0)
  } catch (err) {
    if (err.response != null && err.response.status === 400) {
      const { data } = err.response
      setGlobalError('Please fix the errors on the page and try again.')
      if (data.validationErrors != null) {
        setFieldErrors(formatErrors(data, 'Contact'))
      } else {
        setFieldErrors({})
      }
    } else if (err.response != null && err.response.status === 422) {
      setGlobalError(err.response.data.message)
      setFieldErrors({})
    } else if (err.response != null && err.response.status === 424) {
      setGlobalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll.')
      setFieldErrors({})
    } else {
      setFieldErrors({})
    }
  }
  window.scrollTo(0, 0)
}

// --mappings
export default connect(state => ({
  defaultAddress: state.defaultAddress,
  defaultContact: state.defaultContact
}))(AddStudentPage)
