import React, { useState, useEffect } from 'react'
import TextField from './TextField'

const regex = /^[0-1][0-9]\/[0-3][0-9]\/([1-2][0-9]{3})$/
const currentYear = new Date().getFullYear()

function fixValue (value, previousText) {
  if (value.slice(0, -1) === previousText) {
    if (value.length >= 5) {
      value = `${value.slice(0, 2)}/${value.slice(3, 5)}/${value.slice(6)}`
    } else if (value.length >= 2) {
      value = `${value.slice(0, 2)}/${value.slice(3)}`
    }
    // Convert single digit day and month values (D/M/YYYY) to DD/MM/YYYY on the fly
    value = value.replace(/(^|\/)([1-9])\/\//g, '$10$2/').replace(/\/\//g, '/')
  }
  return value.slice(0, 10)
}

function isoToUsa (iso) {
  const parts = iso.split('-')
  if (parts.length === 3) {
    return [parts[1], parts[2], parts[0]].join('/')
  } else {
    return iso
  }
}

function usaToIso (usa) {
  const parts = usa.split('/')
  if (parts.length === 3) {
    return [parts[2], parts[0], parts[1]].join('-')
  } else {
    return usa
  }
}

function checkFormat (usa, minYear, maxYear) {
  const result = regex.exec(usa)
  if (result == null) {
    return false
  }
  const year = ~~result[1]
  return year >= minYear && year <= maxYear
}

function checkValidity (usa, active, fieldErrors, name, minYear, maxYear) {
  if (active) {
    return checkFormat(`${usa}${'01/01/2001'.slice(usa.length)}`, minYear, maxYear)
  } else {
    return checkFormat(usa, minYear, maxYear) || (fieldErrors[name] == null && usa.length === 0)
  }
}

export default function DatePicker (props) {
  const {
    className = '',
    name = '',
    label,
    maxYearsInPast = 100,
    maxYearsInFuture = 1,
    required = false,
    fieldErrors = {},
    value = '',
    onChange,
    setter,
    ...rest
  } = props

  const usa = isoToUsa(value)
  const [previousText, setPreviousText] = useState('')
  const [active, setActive] = useState(false)
  const [valid, setValid] = useState(true)

  const minYear = currentYear - maxYearsInPast
  const maxYear = currentYear + maxYearsInFuture

  useEffect(() => {
    setValid(checkValidity(usa, active, fieldErrors, name, minYear, maxYear))
  }, [fieldErrors, active])

  const id = name.replace(/[^a-zA-Z0-9]/g, '-')

  return (
    <TextField
      invalid={!valid}
      label={`${label != null ? label : name}${required ? ' *' : ''}`}
      helpText={{ children: active || !valid ? 'mm/dd/yyyy' : null }}
      id={id}
      type='tel'
      value={usa}
      className={className}
      onChange={evt => {
        const fixed = fixValue(evt.target.value, previousText)
        const iso = usaToIso(fixed)
        const newValid = checkValidity(fixed, active, fieldErrors, name, minYear, maxYear)
        setValid(newValid)

        setPreviousText(fixed)
        if (setter != null) {
          setter(iso)
        } else {
          evt.target.value = iso
          onChange(evt, newValid)
        }
      }}
      onBlur={() => setActive(false)}
      onFocus={() => setActive(true)}
      {...{ ...rest }}
    />
  )
}
