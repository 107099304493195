import React, { useEffect, useContext } from 'react'
import { HeaderContext, defaultPageTitle } from '../App'
import Button from '~/components/Button'

import '../scss/Home.scss'

import MainImage from '~/images/fttc-hero.jpg'
import LlImage from '~/images/lifelong-learning.jpg'
import SqImage from '~/images/summer-quest.jpg'

// --vars
const heroImageStyle = {
  backgroundImage: `url(${MainImage})`
}
const llPathImageStyle = {
  backgroundImage: `url(${LlImage})`
}
const sqPathImageStyle = {
  backgroundImage: `url(${SqImage})`
}

// --components
function Home (props) {
  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: defaultPageTitle })
  }, [])

  return (
    <div>
      <br />
      <br />
      <br />
      <div className='page home'>
        <div className='page-header'>
          <div className='hero-image' style={heroImageStyle} alt='Students at Francis Tuttle' />
          <div className='text'>
            <h2>Welcome to the Francis Tuttle Student Enrollment Portal</h2>
            <p>
            Congratulations on taking the first step toward becoming the future you! This is where you will find the course or courses that will help you gain new skills or advance skills you may already have. Feel free to search through our list of classes to find something that fits your life and future plans.
            </p>
          </div>
        </div>
        <div className='body'>
          <div className='enroll'>Enroll Today</div>
          <h3>Your future starts here!</h3>
          <div className='paths'>
            <div className='path'>
              <h4>Lifelong Learning</h4>
              <div className='path-hero' style={llPathImageStyle} alt='Summerquest Students' />
              <div>
                Lifelong Learning classes include the many ways you can advance as a professional, continuing the education you need to take on the next challenge. There are also classes for various activities and hobbies that provide personal enrichment. Search for yourself and come see our experienced instructors to begin a fun-filled learning experience!
                <Button
                  label='Enroll in Lifelong Learning'
                  onClick={() => {
                    window.scrollTo(0, 0)
                    props.history.push('/lifelong-learning')
                  }}
                />
              </div>
            </div>
            <div className='path'>
              <h4>Summer Quest</h4>
              <div className='path-hero' style={sqPathImageStyle} alt='Summerquest Students' />
              <div>
              This annual program offers students ages 11-15 the opportunity to take fun and interactive classes during the month of June. Students can choose from a variety of classes that fit every interest, from cooking to robotics, and everything in between.
                <Button
                  label='Enroll in Summer Quest'
                  onClick={() => {
                    window.scrollTo(0, 0)
                    props.history.push('/summer-quest')
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// --functions

// --mappings
export default Home
