import React from 'react'

import {
  List as NList,
  SimpleListItem as NSimpleListItem,
  ListDivider as NListDivider
} from '@rmwc/list'

import '@rmwc/list/styles'
import './List.scss'

// --vars

// --components
function List ({ styleClasses, children, ...attributes }) {
  const classes = ['nlist'].concat(styleClasses)

  return (
    <NList
      {...attributes}
      className={classes.join(' ')}
    >
      {children}
    </NList>
  )
}

export function SimpleListItem ({ styleClasses, ...attributes }) {
  const classes = ['nitem'].concat(styleClasses)

  return (
    <NSimpleListItem
      {...attributes}
      className={classes.join(' ')}
    />
  )
}

export function ListDivider ({ styleClasses, ...attributes }) {
  const classes = ['ndivider'].concat(styleClasses)

  return (
    <NListDivider
      {...attributes}
      className={classes.join(' ')}
    />
  )
}
// --functions

// --mappings
export default List
