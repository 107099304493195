import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import './Footer.scss'

export default function Header (props) {
  return (
    <footer className='main-footer'>
      <div className='container'>
        <div>© {moment().tz('America/Chicago').format('YYYY')} Francis Tuttle Technology Center</div>
        <div>
          <Link to='/terms' onClick={() => window.scrollTo(0, 0)}>Terms of Use</Link>
          |<Link to='/privacy' onClick={() => window.scrollTo(0, 0)}>Privacy Information</Link>
          |<Link to='/accessibility' onClick={() => window.scrollTo(0, 0)}>Accessibility</Link>
        </div>
      </div>
    </footer>
  )
}
