import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu'
import Icon from './Icon'
import List, { ListDivider } from './List'
import Logo from '~/images/fttc_logo.png'

import '@rmwc/menu/styles'
import './MainMenu.scss'

// --vars

// --components
function MainMenu ({ pageTitle }) {
  const [open, setOpen] = useState(false)

  function toggle () {
    setOpen(!open)
  }
  function close () {
    setOpen(false)
  }
  return (
    <span className='main-menu'>
      <MenuSurfaceAnchor>
        <Menu
          open={open}
          onSelect={(evt) => close()}
          onClose={(evt) => close()}
        >
          <div className='branding'>
            <Link to='/'><img src={Logo} alt='Francis-Tuttle Logo' onClick={() => toggle()} /></Link>
          </div>
          <List>
            <MainMenuLink to='/students' icon='people_alt' text='Add a Student' />
            <ListDivider />
            <MainMenuLink to='/lifelong-learning' icon='menu_book' text='Lifelong Learning' />
            <MainMenuLink to='/ll-courses' icon='search' text='Courses' />
            <ListDivider />
            <MainMenuLink to='/summer-quest' icon='menu_book' text='Summer Quest' />
            <MainMenuLink to='/sq-courses' icon='search' text='Courses' />
          </List>
        </Menu>
      </MenuSurfaceAnchor>
      <div onClick={() => toggle()} className='main-menu-link'>
        <Icon icon='menu' />{pageTitle}
      </div>
    </span>
  )
}

function MainMenuLink ({ to, icon, text }) {
  const history = useHistory()

  return (
    <MenuItem
      onClick={() => {
        history.push(to, 'replace')
      }}
      tabIndex={0}
      className='menu-item'
    >
      <div className='menu-item--inner'>
        <Icon icon={icon} />
        {text}
      </div>
    </MenuItem>
  )
}

// --functions

// --mappings
export default MainMenu
