import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../../App'
import Bottleneck from 'bottleneck/es5'
import qs from 'querystring'
import Chip, { ChipSet } from '../Chip'

import SearchPage from '~/components/shared/SearchPage'
import ErrorMessage from '~/components/ErrorMessage'
import Checkbox from '../Checkbox'

import Course from './Course'
import { useLocations, useCourseCategories } from '~/refdata'
import { request } from 'graphql-request'

import searchQuery from './search-query'
import './CourseSearchPage.scss'

// --vars
const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 800,
  highWater: 1
})

// --components
export default function CourseSearchPage (props) {
  const { history, location } = props
  const locations = useLocations()
  const courseCategories = useCourseCategories()

  const [query, setQuery] = useState(() => {
    const parsed = qs.parse(location.search.slice(1))
    return parsed.q === 'undefined' || parsed.q == null ? '' : parsed.q
  })

  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [locationsSelected, setLocationsSelected] = useState([])

  const [globalError, setGlobalError] = useState(null)
  const [showFilterWarning, setShowFilterWarning] = useState(false)
  const [closeCards, setCloseCards] = useState(false)

  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Lifelong Learning Courses' })
  }, [])

  function toggleCategoryFilter (filter) {
    if (!categoriesSelected.includes(filter)) {
      setCategoriesSelected([...categoriesSelected, filter])
    } else {
      setCategoriesSelected(categoriesSelected.filter(cat => cat !== filter))
    }
  }

  useEffect(() => {
    history.push({ search: `?q=${query}` })
  }, [query])

  useEffect(() => {
    setLocationsSelected(
      locations.map(loc => loc.name)
    )
  }, [locations])

  // NOTE: this is where the query gets fired.
  useEffect(() => {
    // load all avaialable by default
    if (locationsSelected.length === 0) {
      setCourses([])
      setShowFilterWarning(true)
      setLoading(false)
      return
    }

    setShowFilterWarning(false)
    setLoading(true)
    ignoreRateLimiting(async () => {
      try {
        const { wedOeCourses } = await request(
          '/api/public/graphql',
          searchQuery,
          { searchText: query, locations: locationsSelected, courseCategories: categoriesSelected })
        setCourses(wedOeCourses)
      } catch (err) {
        // FIXME: eat error for now
      } finally {
        setLoading(false)
      }
    })
  }, [query, locationsSelected, categoriesSelected])

  const filterMarkup = (
    <>
      <div className='course-categories'>
        <div>
          {
            `To improve your search experience, 
            you may click on a category or multiple categories, 
            and specify a location to see only classes that meet
            that criteria.`
          }
        </div>
        {
          courseCategories.length > 0 && (
            <ChipSet choice>
              {
                courseCategories.map((category, idx) => {
                  const { description, name } = category
                  return (
                    <Chip
                      key={idx}
                      label={description}
                      selected={categoriesSelected.includes(name)}
                      checkmark
                      onInteraction={
                        () => toggleCategoryFilter(name)
                      }
                    />
                  )
                })
              }
            </ChipSet>
          )
        }
      </div>
      <Filters
        {...{
          locations,
          locationsSelected,
          setLocationsSelected
        }}
      />
    </>
  )

  const headerMarkup = (
    <>
      <div className='page-header'>
        <div className='contents'>
        Search for courses
        </div>
      </div>
    </>
  )

  return (
    <div>
      <div className='page-header' />
      <SearchPage {...{ query, setQuery, loading, filters: filterMarkup, header: headerMarkup }} className='wed-oe'>
        {
          (!loading && showFilterWarning) && (
            <ErrorMessage
              warning
              message='Try changing your keywords or categories, and make sure at least one location is selected.'
            />
          )
        }
        <div className='results-count'>
          {
            !loading && (
              <>
                {`${courses.length} Result${courses.length === 1 ? '' : 's'}`}
                {(courses.length < 1 && !showFilterWarning) && <div>Consider changing your search keywords and filters</div>}
              </>
            )
          }
          {
            globalError && <ErrorMessage message={globalError} />
          }
        </div>
        <div className='results'>
          {
            courses && courses.map(course =>
              <Course
                key={course.id}
                course={course}
                goToAddStudent={() => {
                  props.history.push('/addstudent')
                  window.scrollTo(0, 0)
                }}
                {...{ closeCards, setCloseCards, globalError, setGlobalError }}
              />
            )
          }
        </div>
      </SearchPage>
    </div>
  )
}

function Filters ({ locations, locationsSelected, setLocationsSelected }) {
  return (
    <div className='filters'>
      <div className='column weeks'>
        {locations.map(({ name }, idx) =>
          <div className='row' key={`${name}-${idx}`}>
            <Checkbox
              onChange={(evt) => {
                if (evt.target.checked) {
                  setLocationsSelected([...locationsSelected, name])
                } else {
                  setLocationsSelected(locationsSelected.filter(x => x !== name))
                }
              }}
              label={name}
              checked={locationsSelected.includes(name)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

// --functions
async function ignoreRateLimiting (fn) {
  try {
    await limiter.schedule(() => fn())
  } catch (err) {
    if (!(err instanceof Bottleneck.BottleneckError)) {
      // Ignore the intentional throttling rejections
      throw err
    }
  }
}
