import React, { useEffect, useContext } from 'react'
import { HeaderContext } from '../App'

export default function PrivacyPage (props) {
  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Privacy Policy' })
  }, [])

  return (
    <div className='page privacy'>

      <div className='page-header'>
        <div className='contents'>
            Your Privacy and Francis Tuttle
        </div>
      </div>

      <div className='page-body'>
        <div className='paragraph'>
            Your privacy is of great importance to us. Information that you submit will be held in strictest
            confidence and will be used only by Francis Tuttle officials to provide admissions, registration,
            enrollment, and other similar services. The information that you submit will be used:
          <ul>
            <li>
                For communication with you regarding the classes in which you enroll, which may include contacting you when class or schedule changes occur
            </li>
            <li>
                To meet legal reporting requirements of the Oklahoma Department of Career and Technology Education
            </li>
            <li>
                To notify you of our future offerings
            </li>
            <li>
                To conduct necessary Francis Tuttle business and meet our responsibilities to you as a Francis Tuttle student,
                such as enrolling you in your chosen courses
            </li>
          </ul>
        </div>

        <div className='paragraph'>
            Under no circumstances will your information be given or sold to unaffiliated third parties.
        </div>
      </div>

      <div className='page-header'>
        <div className='contents'>
            What We Collect and Where We Store It
        </div>
      </div>

      <div className='page-body'>
        <div className='paragraph'>
            In the process of registering you for classes, in order to uniquely identify you in our database and
            to make certain discounts available to qualified individuals, we will collect the following information:
          <ul>
            <li>
                Name and address
            </li>
            <li>
                Social Security Number (voluntary)
            </li>
            <li>
                Date of birth
            </li>
            <li>
                Courses in which you enroll
            </li>
            <li>
                Credit card information
            </li>
            <li>
                Employer affiliation (where applicable to course enrollment)
            </li>
          </ul>
        </div>

        <div className='paragraph'>
            Both your personal data and your course enrollment information are permanently stored in the
            Francis Tuttle Student Accounting database. In addition, a list of the classes you selected is
            stored in a web-accessible database that allows us to provide you with personal services in the future,
            such as a list of other courses in which individuals who enrolled in your course also chose to enroll.
            No payment information (e.g., your credit card number) will be stored permanently on Internet-connected systems.
        </div>
      </div>

      <div className='page-header'>
        <div className='contents'>
            A Safe and Secure Process
        </div>
      </div>

      <div className='page-body'>
        <div className='paragraph'>
            We are very aware of the need for Internet security, and make every effort to prevent others from inadvertently
            or maliciously gaining access to your records. Whenever we collect information from you across the Internet,
            we employ a secure site, which uses SSL (secure socket layer) 128 bit encrypted connections to keep your information as secure as possible.
        </div>
      </div>

    </div>
  )
}
