import React, { useEffect, useContext } from 'react'
import { HeaderContext } from '../App'

export default function AccessbilityPage (props) {
  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Accessibility Information' })
  }, [])

  return (
    <div className='page accessibility'>
      <div className='page-header'>
        <div className='contents'>
            Policy
        </div>
      </div>

      <div className='page-body'>
        <div className='paragraph'>
            Francis Tuttle is committed to complying with Oklahoma law requiring electronic and information technology accessibility,
            as defined in Section 4.3 of the  "Information Technology Accessibility Standards",
            available <a href='https://www.ok.gov/accessibility/Oklahoma_EITA_Standards.html' target='_blank' rel='noopener noreferrer'>here</a>.
        </div>
      </div>
    </div>
  )
}
