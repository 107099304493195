import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import env from '~/environment'
import http from '~/http'
import { maxExtensions, showExtensionAt } from '~/constants/timer'
import Icon from '../components/Icon'
import MainMenu from './MainMenu'
import { HeaderContext } from '../App'
import { useSeasonInfo } from '~/refdata'

import './Header.scss'

function Header (props) {
  const sqSeasonInfo = useSeasonInfo()

  const { cart } = props

  const { headerInfo } = useContext(HeaderContext)
  const { pageTitle, isSqView } = headerInfo

  const [globalError, setGlobalError] = useState(null)
  const [timerText, setTimerText] = useState('')

  useEffect(() => {
    doTick(props, setTimerText)
    const interval = setInterval(() => doTick(props, setTimerText), 1000)
    return () => clearInterval(interval)
  }, [cart.expiresAt])

  const showTimer = cart.expiresAt != null
  const expiringSoon = showTimer && cart.expiresAt - Date.now() <= showExtensionAt
  const showExtensionButton = expiringSoon && cart.extensions < maxExtensions
  const timerClassName = globalError != null ? 'extend-error' : expiringSoon ? 'expiring-soon' : 'notice'

  const notProduction = env.NAME !== 'prod'

  return (
    <header className='main-header' id='header'>
      <div className='header-row primary'>
        <MainMenu {...{ pageTitle }} />
        <div className='actions'>
          {
            cart.reservations.length > 0 && (
              <Link to='/cart' onClick={() => window.scrollTo(0, 0)} className='cart-link'>
                <span className='total'>{`$${cart.total.toFixed(2)}`}</span>
                <span className='count'>{`(${cart.reservations.length})`}</span>
                <Icon icon={{ icon: 'shopping_cart', size: 'large' }} />
              </Link>
            )
          }
        </div>
      </div>
      {showTimer && (
        <BannerShell mode={timerClassName} otherClass='timer'>
          <span className='cell'>{globalError != null ? globalError : timerText}</span>
          {showExtensionButton && (
            <span className='extend' onClick={extendCart.bind(null, props, setGlobalError)}>
              Need more time?
            </span>
          )}
        </BannerShell>
      )}
      {notProduction && <BannerShell mode='info'>This is a test / training environment</BannerShell>}
      {isSqView && <SqBanner mode='notice' {...{ sqSeasonInfo }} />}
    </header>
  )
}

function SqBanner ({ sqSeasonInfo, mode }) {
  const [now, setNow] = useState(moment())

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment())
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  if (sqSeasonInfo === null || sqSeasonInfo === undefined) {
    return <BannerShell {...{ mode }}>Summer Quest is now over. Join us next year for more Summer Quest!</BannerShell>
  }

  const zone = 'America/Chicago'
  const showDate = moment(sqSeasonInfo.showDate).tz(zone).startOf('day')
  const hideDate = moment(sqSeasonInfo.hideDate).tz(zone).startOf('day')

  // NOTE: These are commented out due to copy change, leaving them here for now for future use.
  // const registrationDate = moment(sqSeasonInfo.registrationDate).tz(zone).startOf('day').format('MMMM D, YYYY')
  // const registrationTime = formatDurationForDisplay(sqSeasonInfo.registrationTime)

  const beginToday = moment().startOf('day')

  if (beginToday.isSameOrAfter(showDate) && now.isBefore(sqSeasonInfo.registrationStarts)) {
    // 0000hrs this morning is after showDate (Season.SelfServicePublishDate)
    // && now is before Season.OnlineRegistrationStartDate at Season.OnlineRegistrationStartTime
    return <BannerShell {...{ mode }}>FUN & Adventure are coming soon for Summer Quest 2024!!</BannerShell>
  } else if (beginToday.isBefore(hideDate)) {
    // 0000hrs this morning is before hideDate (week.endDate for Season's last week)
    return <BannerShell {...{ mode }}>FUN & Adventure are NOW OPEN for enrollment in Summer Quest 2024!!</BannerShell>
  } else if (beginToday.isSameOrAfter(hideDate)) {
    // 0000hrs this morning is after hideDate (week.endDate for Season's last week)
    return <BannerShell {...{ mode }}>Summer Quest registration is now over. Join us next year for more Summer Quest!</BannerShell>
  } else {
    return <span />
  }
}

function BannerShell ({ children, mode, otherClass = null }) {
  return <div className={`header-row banner banner--${mode}${otherClass != null ? ' ' + otherClass : ''}`}>{children}</div>
}

// --functions
function formatTimer (expiresAt) {
  if (expiresAt == null) {
    return null
  }

  const m = moment(expiresAt)
  const minutes = m.diff(Date.now(), 'minutes')
  const seconds = m.diff(Date.now(), 'seconds') % 60
  if (minutes === 0) {
    if (seconds > 1) {
      return `${seconds} seconds remaining`
    } else if (seconds === 1) {
      return '1 second remaining'
    } else {
      return 'Cart expired'
    }
  } else if (minutes === 1) {
    return `1 minute, ${seconds} seconds remaining`
  } else if (seconds > 30) {
    return `${minutes + 1} minutes remaining`
  } else {
    return `${minutes} minutes remaining`
  }
}

function doTick (props, setTimerText) {
  if (props.cart.expiresAt != null && props.cart.expiresAt < Date.now()) {
    props.dispatch({
      type: 'CLEAR_CART',
      data: {}
    })
  } else {
    setTimerText(formatTimer(props.cart.expiresAt))
  }
}

async function extendCart (props, setGlobalError) {
  const payload = {
    cartPublicId: props.cart.publicId
  }

  try {
    const response = await http(setGlobalError, http => http.post('/api/SelfService/Cart/Extend', payload))
    props.dispatch({
      type: 'EXTEND_CART',
      data: {
        cart: response.data.cart
      }
    })
  } catch (err) {
    // No additional error handling
  }
}

// --mappings
export default connect(state => ({
  cart: state.cart
}))(Header)
