/* global document: false */
/* eslint-disable import/first */

import 'react-app-polyfill/stable'
import { createStore } from 'redux'
import rootReducer, { initialStates, latestStateVersion } from '~/reducers'
import { Provider } from 'react-redux'
import React from 'react'
import { createRoot } from 'react-dom/client'
import addRaygunLogging from './logging'
import App from '~/App'

const container = document.getElementById('root')
const root = createRoot(container)

const getLocalStorage = (item) => window.localStorage.getItem(item) ? JSON.parse(window.localStorage.getItem(item)) : {}

const store = (function () {
  const currentStateVersion = ~~getLocalStorage('version').version

  const persistedState = currentStateVersion < latestStateVersion ? {} : getLocalStorage('reduxState')
  Object.keys(initialStates).forEach(r => {
    persistedState[r] = initialStates[r]
  })
  window.localStorage.setItem('reduxState', JSON.stringify(persistedState))
  const store = createStore(
    rootReducer,
    persistedState
  )
  store.subscribe(function () {
    const state = store.getState()
    window.localStorage.setItem('reduxState', JSON.stringify(state))
  })

  window.localStorage.setItem('version', JSON.stringify({ version: latestStateVersion }))

  return store
})()

addRaygunLogging(store)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
