import React from 'react'
import TextField from '../TextField'
import ErrorMessage from '~/components/ErrorMessage'
import Loader from '~/components/shared/Loader'

export default function SearchPage ({ children, query, setQuery, className, filters, categories = null, errorMessage, loading, header = null }) {
  const classes = `page course-search ${className || ''}`.trim()
  return (
    <div className={classes}>
      {
        header != null
          ? header
          : (
            <div className='page-header'>
              <div className='contents'>
                Search by keyword
              </div>
            </div>
          )
      }

      <div className='page-body'>
        <TextField
          className='search-bar'
          name='query'
          label='Search...'
          value={query}
          onChange={evt => {
            evt.preventDefault()
            setQuery(evt.target.value.slice(0, 50))
          }}
        />
        {
          categories != null && categories
        }
        {
          filters != null && filters
        }
        <div className='results-count'>
          {
            errorMessage &&
              <ErrorMessage message={errorMessage} />
          }
        </div>
        {loading && <Loader />}
        {children}
      </div>
    </div>
  )
}
