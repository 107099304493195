import React from 'react'

const SVGIcon = props => {
  const {
    icon,
    viewBox = '0 0 16 16',
    width,
    height,
    className = '',
    title,
    style,
    onClick
  } = props
  const icons = {
    cart: (
      <g>
        <circle cx='9' cy='21' r='1' />
        <circle cx='20' cy='21' r='1' />
        <path d='M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6' />
      </g>
    ),
    checkcircle: <path d='M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM7.05,11.88l-2.95-3L5.23,7.8,7,9.58l4.6-4.86,1.16,1.1Z' />,
    close: <path d='M12.85,1.54,8,6.38,3.15,1.54,1.54,3.15,6.38,8,1.54,12.85l1.61,1.61L8,9.62l4.85,4.84,1.61-1.61L9.62,8l4.84-4.85Z' />,
    closecircle: <path d='M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm3.92,10.8L10.8,11.92,8,9.12l-2.8,2.8L4.08,10.8,6.88,8,4.08,5.2,5.2,4.08,8,6.88l2.8-2.8L11.92,5.2,9.12,8Z' />,
    error: <path d='M16,14.65,8,1.35,0,14.65ZM8.73,13.2H7.27V11.75H8.73Zm0-2.91H7.27V5.93H8.73Z' />,
    link: (
      <g>
        <path d='M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71' />
        <path d='M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71' />
      </g>
    ),
    replay: (
      <g transform='scale(0.75 0.75)'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z' />
      </g>
    ),
    more: (
      <g>
        <circle cx='8' cy='8' r='1' />
        <circle cx='12.6666' cy='8' r='1' />
        <circle cx='3.3333' cy='8' r='1' />
      </g>
    ),
    phone: <path d='M15.11,10.67H11.56L9.28,12.94A17.23,17.23,0,0,1,3,6.81a.43.43,0,0,0,0,0L5.33,4.44V.89A.84.84,0,0,0,4.44,0H.89A.85.85,0,0,0,0,.89V2.67C0,9,8,16,13.33,16h1.78a.85.85,0,0,0,.89-.89V11.56A.84.84,0,0,0,15.11,10.67Z' />,
    plus: <path d='M6.86 0v6.86H0v2.28h6.86V16h2.28V9.14H16V6.86H9.14V0z' />,
    search: (
      <path d='M10.76,9.55h-.71l-.2-.19a5.58,5.58,0,0,0,1.41-3.73A5.59,5.59,0,0,0,5.63,0,5.59,5.59,0,0,0,0,5.63a5.59,5.59,0,0,0,5.63,5.63A5.62,5.62,0,0,0,9.16,10l.23.23v.66L14.46,16l1.36-1.37ZM1.61,5.63a4,4,0,0,1,4-4,4,4,0,0,1,4,4,4,4,0,0,1-4,4A4,4,0,0,1,1.61,5.63Z' />
    ),
    trashcan: <path d='M6.32,0,5.47.84H2.11V2.53H13.89V.84H10.53L9.68,0ZM3,3.37v11A1.68,1.68,0,0,0,4.63,16h6.74a1.68,1.68,0,0,0,1.68-1.68V3.37Z' />,
    userPlus: (
      <g>
        <circle cx='6.86' cy='3.05' r='3.05' />
        <path d='M11.43,6.86A4.57,4.57,0,1,0,16,11.43,4.58,4.58,0,0,0,11.43,6.86Zm2.28,5.33H12.19v1.52H10.67V12.19H9.14V10.67h1.53V9.14h1.52v1.53h1.52Z' />
        <path d='M5.33,11.43a6.12,6.12,0,0,1,.8-3C3.73,8.62,0,9.75,0,11.81v1.9H5.78A5.94,5.94,0,0,1,5.33,11.43Z' />
      </g>
    ),
    users: (
      <g>
        <circle cx='13' cy='5.33' r='1.67' />
        <path d='M13,8.33a3.37,3.37,0,0,0-1.89.53A4.53,4.53,0,0,1,13.28,11l0,0H16v-.89A3.17,3.17,0,0,0,13,8.33Z' />
        <path d='M13,8.33a3.37,3.37,0,0,0-1.89.53A4.53,4.53,0,0,1,13.28,11l0,0H16v-.89A3.17,3.17,0,0,0,13,8.33Z' />
        <circle cx='3' cy='5.33' r='1.67' />
        <path d='M3,8.33a3.37,3.37,0,0,1,1.89.53A4.53,4.53,0,0,0,2.72,11l0,0H0v-.89A3.17,3.17,0,0,1,3,8.33Z' />
        <path d='M8,3a2.67,2.67,0,1,0,2.67,2.67A2.67,2.67,0,0,0,8,3Z' />
        <path d='M8,9a4.93,4.93,0,0,0-4.67,2.67V13h9.34V11.67A4.93,4.93,0,0,0,8,9Z' />
      </g>
    )
  }

  return (
    <svg icon={icon} className={`icon icon-${icon} ${className}`} style={style} viewBox={viewBox} width={width} height={height} onClick={onClick}>
      {title != null && <title>{title}</title>}
      {icons[icon]}
    </svg>
  )
}

export default SVGIcon
