import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import Card from '../components/Card'
import { CircularProgress } from '@rmwc/circular-progress'
import { ThemeProvider } from '@rmwc/theme'
import themeColors from '../constants/themeColors'

import '@rmwc/circular-progress/styles'
import './Loader.scss'

// --vars
const LoaderContext = createContext()
const options = { primary: themeColors.primary }

// --components
export function LoaderProvider ({ children }) {
  const grabFocus = useRef()
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (showLoader) {
      trapFocus()
    }
  }, [showLoader])

  function trapFocus () {
    grabFocus.current.focus()
  }

  return (
    <LoaderContext.Provider value={[showLoader, setShowLoader]}>
      {
        showLoader && (
          <div className='loader-scrim'>
            <Card
              className='loader'
              role='alert'
              aria-labelledby='dialog-title'
              aria-modal='true'
            >
              <h2
                id='dialog-title'
                tabIndex='-1'
                ref={grabFocus}
                onBlur={() => trapFocus()}
              >
                Loading. Please Wait.
              </h2>
              <div className='spinner'>
                <ThemeProvider options={options}>
                  <CircularProgress size={150} theme='primary' />
                </ThemeProvider>
              </div>
            </Card>
          </div>
        )
      }
      {children}
    </LoaderContext.Provider>
  )
}

// --functions
function useShowLoader (show) {
  const [, setShowLoader] = useContext(LoaderContext)

  return (show) => { setShowLoader(show) }
}

// --mappings
export default useShowLoader
