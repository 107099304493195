import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Bottleneck from 'bottleneck/es5'
import moment from 'moment'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog'
import recaptcha from '~/helpers/loadRecaptcha'
import http, { preparePayload, formatErrors, wrapButtonToggle } from '~/http'
import { request } from 'graphql-request'
import SearchResultCard from '~/components/shared/SearchResultCard'
import ErrorMessage from '~/components/ErrorMessage'
import Button from '~/components/Button'
import { formatDurationForDisplay, dateRange, timeRange, fullDate } from '~/helpers/dateAndTime'
import useShowLoader from '../Loader'
import TextField from '../TextField'

import './Course.scss'

// --vars
const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 800,
  highWater: 1
})

const capacityQuery = `
query ($id: Int!) {
    wedOeSection(id: $id) {
      courseSectionNumber
      location
      meetingPattern
      maxCapacity
      availableCapacity
      startDate
      endDate
      startTime
      endTime
      id
    }
  }
`

// --components
function Course (props) {
  const {
    course,
    cart,
    students,
    goToAddStudent,
    preSelected = null,
    closeCards,
    globalError,
    setGlobalError
  } = props

  const [selectedSection, setSelectedSection] = useState(preSelected)
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(false)

  useEffect(() => {
    if (closeCards) {
      setSelectedSection(null)
    }
  }, [closeCards])

  const { id, title, description, sections } = course

  return (
    <SearchResultCard
      htmlId={`c${id}`}
      title={title}
      description={description}
      errorMessage={globalError}
      {...{ setSelectedSection, selectedSection }}
    >
      {
        selectedSection == null
          ? (
            <>
              {
                sections.map(section =>
                  <WedSectionSearchResult
                    {...{ section, setSelectedSection, course }}
                    key={section.id}
                  />
                )
              }
            </>
          )
          : (
            <SelectedSection {...{
              selectedSection,
              course,
              students,
              cart,
              goToAddStudent,
              setRemoveButtonDisabled,
              removeButtonDisabled,
              addButtonDisabled,
              setAddButtonDisabled,
              setGlobalError,
              parentProps: props
            }}
            />
          )
      }
    </SearchResultCard>
  )
}

function WedSectionSearchResult ({ section, setSelectedSection, course }) {
  const { courseSectionNumber, startDate, endDate, startTime, location, meetingPattern, availableCapacity, isCeEligible } = section

  return (
    <div key={`section-${courseSectionNumber}`} className='item row unselected-section'>
      <div>{location}
        {isCeEligible && <div>CE</div>}
      </div>
      <div>
        {
          !moment(startDate).isSame(endDate)
            ? <>{fullDate(startDate)} -<br />{fullDate(endDate)}</>
            : <>{fullDate(startDate)}</>
        }

      </div>
      {
        location !== 'Online' && (
          <>
            <div>
              <div className='meets-on'>
                {meetingPattern || '---'}
              </div>
              <div>
                {startTime != null ? formatDurationForDisplay(startTime) : '---'}
              </div>
            </div>
          </>
        )
      }
      <div className='row button-column'>
        <Button
          label={availableCapacity > 0 ? 'Details' : 'Full'}
          unelevated={availableCapacity > 0}
          dense
          onClick={() => {
            setSelectedSection(section)
            // If not visible, scroll to the top of the current card,
            // taking into account the height of the floating header bar
            const top = Math.round(document.getElementById(`c${course.id}`).getBoundingClientRect().top)
            const header = document.getElementById('header').scrollHeight
            if (top < header) { // TODO: fix this for MS Edge
              window.scrollTo(0, window.pageYOffset + top - header)
            }
          }}
        />
      </div>
    </div>
  )
}

function SelectedSection ({
  selectedSection,
  cart,
  course,
  students,
  goToAddStudent,
  setRemoveButtonDisabled,
  removeButtonDisabled,
  addButtonDisabled,
  setAddButtonDisabled,
  setGlobalError,
  parentProps
}) {
  const {
    courseSectionNumber,
    startDate,
    endDate,
    startTime,
    endTime,
    location,
    meetingPattern,
    notEnrollableReason,
    id,
    tuitionDisplay,
    suppliesDisplay,
    bookDisplay,
    testDisplay,
    priceDisplay,
    isCeEligible,
    ceEligibility,
    scheduleNotes
  } = selectedSection

  const enrollable = notEnrollableReason == null
  const [fetchedSection, setFetchedSection] = useState(null)

  const showLoader = useShowLoader()

  useEffect(() => {
    if (selectedSection != null) {
      ignoreRateLimiting(async () => {
        try {
          const { wedOeSection } = await request('/api/public/graphql', capacityQuery, { id: id })
          setFetchedSection(wedOeSection)
        } catch (err) {
          // FIXME: eat error for now
        }
      })
    } else {
      setFetchedSection(null)
    }
  }, [selectedSection, cart.reservationsByType.WEDOE])

  const cartContentsBySection = cart.reservationsByType.WEDOE.reduce((acc, r) => {
    acc[`${r.section.id} ${r.studentPublicId}`] = r
    return acc
  }, {})

  const cartContentsByCourse = cart.reservationsByType.WEDOE.reduce((acc, r) => {
    acc[`${r.courseId} ${r.studentPublicId}`] = r
    return acc
  }, {})

  const studentCourseDuplicates = (selectedSectionId) =>
    students.filter((student) =>
      cartContentsByCourse[`${course.id} ${student.publicId}`] != null &&
      cartContentsBySection[`${selectedSectionId} ${student.publicId}`] == null
    )

  const { capacityDisplay, capacityClassName, isFull } = fetchedSection != null
    ? {
      capacityDisplay: `${fetchedSection.availableCapacity} seat${fetchedSection.availableCapacity > 1 ? 's' : ''} open`,
      capacityClassName: fetchedSection.availableCapacity <= 0 ? 'full' : fetchedSection.availableCapacity <= 3 ? 'near-full' : 'not-full',
      isFull: fetchedSection.availableCapacity <= 0
    }
    : {
      capacityDisplay: '0 seats open',
      capacityClassName: '',
      isFull: false
    }

  return (
    <>
      {
        studentCourseDuplicates(id).map((student) => (
          <ErrorMessage
            key={`dup-${course.id}-${student.publicId}`}
            warning
            message={`Warning: Your cart already contains an enrollment into this course for ${student.firstName} ${student.lastName} `}
          />
        ))
      }
      <div className='item row course-section-data'>
        <span>#{courseSectionNumber}</span>
        <span className={capacityClassName}>{capacityDisplay}</span>
      </div>
      <div className='item row selected-section'>
        <span>
          <div>Tuition</div>
          <div>{tuitionDisplay}</div>
        </span>
        <span>
          <div>Supplies</div>
          <div>{suppliesDisplay}</div>
        </span>
        <span>
          <div>Book</div>
          <div>{bookDisplay}</div>
        </span>
        <span>
          <div>Test</div>
          <div>{testDisplay}</div>
        </span>
      </div>
      <div className='item row selected-section total-price'>
        <span>Total Price: {priceDisplay}</span>
      </div>
      <div className='item row selected-section'>
        <span className='info'>{location}</span>
        <span className='info'>{dateRange(startDate, endDate)}</span>
      </div>
      {
        location !== 'Online' && (
          <div className='item row selected-section'>
            <div className='info'>{meetingPattern}</div>
            <div className='info'>{startTime != null && timeRange(startTime, endTime)}</div>
          </div>
        )
      }
      {
        scheduleNotes != null && (
          <div className='item row selected-section'>
            {scheduleNotes}
          </div>
        )
      }
      {
        isCeEligible && (
          <div className='item row selected-section'>
            Eligible for continuing education credit.
          </div>
        )
      }

      {
        !enrollable && (
          <>
            <div className='item row no-student'>{notEnrollableReason}</div>
            <div className='item row no-student'>
              Please give us a call at 405-717-4900 between the hours of 8:00 AM and 4:30 PM and we'll assist you.
            </div>
          </>
        )
      }

      {
        students.length === 0 && enrollable && (
          <>
            <div className='item row no-student'>Let's start by registering a student.</div>
            <div className='item row no-student'>
              <Button
                label='Add Student'
                icon='person_add'
                unelevated
                onClick={goToAddStudent}
              />
            </div>
          </>
        )
      }

      {
        enrollable && students.map((student, idx) => {
          const reservation = cartContentsBySection[`${id} ${student.publicId}`]
          const ageOnStartDate = moment(startDate).diff(student.birthDate, 'years')
          const ageError = ageOnStartDate < 16 ? 'Too young' : null
          const getAction = function () {
            if (reservation != null) {
            // Remove from cart
              return (
                <Button
                  icon='delete'
                  label='Remove'
                  outlined
                  dense
                  disabled={removeButtonDisabled}
                  onClick={
                    wrapButtonToggle(
                      setRemoveButtonDisabled,
                      async () => {
                        showLoader(true)
                        await removeFromCart(parentProps, setGlobalError, reservation, student)
                        showLoader(false)
                      })
                  }
                />
              )
            } else if (isFull) {
              return <span>Full</span>
            } else if (ageError != null) {
              return <span>{ageError}</span>
            } else {
            // Add to cart
              return (
                <>
                  {
                    !isCeEligible
                      ? (
                        <Button
                          label='Add to Cart'
                          unelevated
                          dense
                          disabled={addButtonDisabled}
                          onClick={
                            wrapButtonToggle(
                              setAddButtonDisabled,
                              async () => {
                                showLoader(true)
                                await addToCart(parentProps, setGlobalError, student, selectedSection)
                                showLoader(false)
                              })
                          }
                        />
                      ) : (
                        <CeLicenseDialogButton {...{ ceEligibility, parentProps, student, selectedSection, setGlobalError, showLoader }} />
                      )
                  }
                </>
              )
            }
          }
          return (
            <div className='item row student' key={`student-selection-${student.publicId}`}>
              <span className='cell'>{`${student.firstName} ${student.lastName}`}</span>
              {getAction()}
            </div>
          )
        })
      }
    </>
  )
}

function CeLicenseDialogButton (props) {
  //
  const { ceEligibility, parentProps, student, selectedSection, setGlobalError, showLoader } = props

  const defaultForm = {}
  const [open, setOpen] = useState(false)
  const [formValues, setFormValues] = useState(defaultForm)
  const [fieldErrors, setFieldErrors] = useState([])
  const [localError, setLocalError] = useState(undefined)

  const isCleet = ceEligibility.includes('CLEET')
  const isInsurance = ceEligibility.filter(type => type.indexOf('Insurance') !== -1).length > 0

  async function captureCeLicense () {
    var token = null
    try {
      token = await recaptcha('captureCeLicense')
    } catch {
      // silently fail on recaptcha error
      return
    }

    const ceLicenses = ceEligibility.reduce((acc, type) => {
      if (formValues[type] != null && formValues[type] !== '') {
        acc.push({ type, number: formValues[type] })
      }
      return acc
    }, [])

    const tempPayload = {
      recaptchaToken: token,
      ceLicenses
    }

    if (formValues.SSN != null && formValues !== '') {
      tempPayload.ssn = formValues.SSN
    }

    if (formValues.npn != null && formValues !== '') {
      tempPayload.npn = formValues.npn
    }

    const payload = preparePayload(tempPayload)

    try {
      showLoader(true)
      await http(setGlobalError, http => http.post(`/api/SelfService/Students/${student.publicId}/AddCeInfo`, payload))
      await addToCart(parentProps, setGlobalError, student, selectedSection)
    } catch (err) {
      if (err.response != null && err.response.status === 400) {
        setLocalError('Please fix the errors on the page and try again.')
        setFieldErrors(formatErrors(err.response.data))
      } else if (err.response != null && err.response.status === 422) {
        setLocalError(err.response.data.message)
        setFieldErrors({})
      } else if (err.response != null && err.response.status === 424) {
        setLocalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll.')
        setFieldErrors({})
      } else {
        setLocalError('Unexpected error: Please refresh the page and try again or call us at 405-717-4900.')
        setFieldErrors({})
      }
    } finally {
      showLoader(false)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={evt => {
          setOpen(false)
          setFormValues(defaultForm)
        }}
        className='full-page'
      >
        <DialogTitle>Continuing Education Credit</DialogTitle>
        {
          localError !== undefined && <ErrorMessage message={localError} />
        }
        <DialogContent>
          <p>
            The class you’ve selected for enrollment is eligible for continuing education credit for {ceLicenseTypeDisplay(ceEligibility)}.
            A license is not required to take the class.
            However, if you are taking the class to meet CE requirements, please provide your license type, license number and/or Social Security number, as required by the governing agency.
            Failure to provide your information could result in a delay in, or not receiving credit for the class.
            If you have further questions, please call 717-4900.
          </p>
          <form name='ce-license' action='POST' method='/' onSubmit={(e) => e.preventDefault()}>
            {
              ceEligibility.map((type, idx) => {
                return (
                  <CeLicenseNumberField
                    {...{ type, formValues, setFormValues, fieldErrors }}
                    key={idx}
                  />
                )
              })
            }
            {
              isCleet && (
                <>
                  <CeLicenseNumberField
                    {...{ formValues, setFormValues, fieldErrors }}
                    type='SSN'
                  />
                  <span>SSN is required for CLEET CE credit</span>
                </>
              )
            }
            {
              isInsurance && (
                <>
                  <CeLicenseNumberField
                    {...{ formValues, setFormValues, fieldErrors }}
                    type='npn'
                  />
                  <span>NPN is required for Insurance CE credit</span>
                </>
              )
            }
          </form>
        </DialogContent>
        <DialogActions>
          <DialogButton action='close'>Cancel</DialogButton>
          <DialogButton
            action='accept'
            onClick={async () => {
              showLoader(true)
              await addToCart(parentProps, setGlobalError, student, selectedSection)
              showLoader(false)
            }}
            isDefaultAction
          >
            Not taking continuing education
          </DialogButton>
          <DialogButton onClick={() => captureCeLicense()}>
            Add my license and enroll
          </DialogButton>
        </DialogActions>
      </Dialog>
      <Button
        label='Add to Cart'
        unelevated
        dense
        onClick={() => setOpen(true)}
      />
    </>
  )
}

function CeLicenseNumberField ({ type, formValues, setFormValues, fieldErrors }) {
  const label = !['SSN', 'npn'].includes(type)
    ? `${propDisplayString(type)} License Number`
    : type === 'SSN'
      ? 'SSN (no hyphens)'
      : type.toUpperCase()

  return (
    <TextField
      className='full-width'
      label={label}
      value={formValues[type]}
      onChange={(evt) => {
        const values = { ...formValues }
        values[type] = evt.target.value
        setFormValues({ ...values })
      }}
    />
  )
}// --functions
async function ignoreRateLimiting (fn) {
  try {
    await limiter.schedule(() => fn())
  } catch (err) {
    if (!(err instanceof Bottleneck.BottleneckError)) {
      // Ignore the intentional throttling rejections
      throw err
    }
  }
}

async function addToCart (props, setGlobalError, student, section) {
  var token = null
  try {
    token = await recaptcha('addToCart')
  } catch {
    // silently fail on recaptcha error
    return
  }

  const payload = {
    recaptchaToken: token,
    cartPublicId: props.cart.publicId,
    studentPublicId: student.publicId,
    sectionId: section.id
  }

  try {
    const response = await http(setGlobalError, http => http.post('/api/self-service/cart/wed/oe/add', payload))

    const startTime = section.startTime != null
      ? `${formatDurationForDisplay(section.startTime)}-${formatDurationForDisplay(section.endTime)}`
      : 'Online'

    props.dispatch({
      type: 'ADD_RESERVATION',
      data: {
        cart: response.data.cart,
        reservations: response.data.reservations,
        newReservationData: {
          courseId: props.course.id,
          courseName: props.course.title,
          section: {
            id: section.id,
            ageGroup: 'Adult',
            availableCapacity: section.availableCapacity,
            campus: section.location,
            courseSectionNumber: section.courseSectionNumber,
            price: section.price,
            notEnrollableReason: section.notEnrollableReason,
            startDate: `${fullDate(section.startDate)}-${fullDate(section.endDate)}`,
            startTime: startTime,
            prerequisite: section.prerequisite,
            isCeEligible: section.isCeEligible
          },
          studentName: `${student.firstName} ${student.lastName}`,
          studentPublicId: student.publicId,
          type: 'WEDOE'
        }
      }
    })
  } catch (err) {
    if (err.response != null && err.response.status === 400) {
      setGlobalError(err.response.data.message)
    } else if (err.response != null && err.response.status === 409) {
      setGlobalError('This student already has a reservation in this class.')
    } else if (err.response != null && err.response.status === 424) {
      setGlobalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll into.')
    }
  }
}

async function removeFromCart (props, setGlobalError, reservation, student) {
  const payload = {
    cartPublicId: props.cart.publicId,
    reservationPublicId: reservation.publicId,
    studentPublicId: student.publicId
  }

  const response = await http(setGlobalError, http => http.post('/api/SelfService/Cart/RemoveEnrollment', payload))
  props.dispatch({
    type: 'REMOVE_RESERVATION',
    data: {
      cart: response.data.cart,
      reservations: response.data.reservations
    }
  })
}

function ceLicenseTypeDisplay (ceEligibility) {
  return propDisplayString(ceEligibility
    .reduce((acc, type, idx) => {
      if (idx === ceEligibility.length - 1) {
        acc += type
      } else if (idx === ceEligibility.length - 2) {
        acc += type + ' and '
      } else {
        acc += type + ', '
      }
      return acc
    }, ''))
}

function propDisplayString (propName) {
  return propName.replace(/([a-z0-9])([A-Z])/g, '$1 $2') // TODO: de-dup this
}

// --mappings
export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(Course)
