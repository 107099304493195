/* eslint-disable import/first */

import rg4js from 'raygun4js'
import env from '~/environment'

rg4js('options', {
  allowInsecureSubmissions: false,
  ignoreAjaxAbort: true,
  ignoreAjaxError: true,
  debugMode: true,
  ignore3rdPartyErrors: false,
  wrapAsynchronousCallbacks: true,
  disableAnonymousUserTracking: false,
  disableErrorTracking: false,
  disablePulse: true
})
rg4js('enableCrashReporting', false)
rg4js('enablePulse', false)
rg4js('saveIfOffline', true)
rg4js('apiKey', env.RAYGUN_API_KEY)
rg4js('withTags', ['public-site', env.BROWSER, env.NAME])

export default function addRaygunLogging (store) {
  rg4js('withCustomData', () => {
    const state = store.getState()
    return { state }
  })

  const errorHandler = (e) => {
    if (e != null && e.message != null && e.message.length > 0 && e.name !== 'Warning' && e.message !== {}.toString()) {
      rg4js('send', e)
    }
  }

  window.addEventListener('error', (event) => {
    errorHandler(event.error)
  })

  window.addEventListener('unhandledrejection', (event) => {
    errorHandler(event.reason)
  })
}

export function logRaygunError (e) {
  rg4js('send', e)
}
