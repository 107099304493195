import env from '~/environment'

const loader = new Promise(function (resolve, reject) {
  const script = document.createElement('script')
  script.src = `https://www.google.com/recaptcha/api.js?render=${env.RECAPTCHA_KEY}`
  script.onload = () => window.grecaptcha.ready(resolve)
  script.onerror = reject
  script.type = 'text/javascript'
  document.getElementsByTagName('head')[0].appendChild(script)
})

export default async function (action) {
  try {
    await loader
  } catch (err) {
    console.error(err)
    const message = err != null && `[${err.message}]` != null ? err.message : ''
    throw new Error(`Could not load reCAPTCHA, please disable any adblocker ${message}`)
  }
  try {
    return await window.grecaptcha.execute(env.RECAPTCHA_KEY, { action })
  } catch (err) {
    console.error(err)
    const message = err != null && `[${err.message}]` != null ? err.message : err
    throw new Error(`reCAPTCHA error, please disable any adblocker ${message}`)
  }
}
