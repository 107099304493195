import React from 'react'
import { terseDate } from '~/helpers/dateAndTime'

import Button from '~/components/Button'
import { useWeek, useTimeSlot } from '~/refdata'
import {
  ages,
  defaultAge,
  fromValue
} from '~/constants/course'

export default function SectionSearchResult ({ section, setSelectedSection, course }) {
  const week = useWeek(section.weekId)
  const timeSlot = useTimeSlot(section.timeSlotId)

  const weekText = `${terseDate(week.startDate)}-${terseDate(week.endDate)}`

  return (
    <div key={`section-${section.courseSectionNumber}`} className='item row unselected-section'>
      <span className='campus-column'>{`${section.campus}`}</span>
      <span className='week-column'>{weekText}</span>
      <span>{timeSlot.name}</span>
      <span>{`${fromValue(section.ageGroup, ages, defaultAge).results}`}</span>
      <span className='row button-column'>
        <Button
          label={section.availableCapacity > 0 ? 'Details' : 'Full'}
          unelevated={section.availableCapacity > 0}
          dense
          onClick={() => {
            setSelectedSection(section)
            // If not visible, scroll to the top of the current card,
            // taking into account the height of the floating header bar
            const top = Math.round(document.getElementById(`c${course.id}`).getBoundingClientRect().top)
            const header = document.getElementById('header').scrollHeight
            if (top < header) {
            // TODO: fix this for MS Edge
              window.scrollTo(0, window.pageYOffset + top - header)
            }
          }}
        />
      </span>
    </div>
  )
}
