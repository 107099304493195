import React from 'react'

import SVGIcon from '~/components/SVGIcon'

export default function ErrorMessage (props) {
  const {
    message,
    warning = false,
    notice = false
  } = props
  const className = warning ? 'warning-message' : notice ? 'notice-message' : 'error-message'

  return (
    <div className='error-message'>
      <span className={`icon-container ${className}`}>
        <SVGIcon icon='error' />
      </span>
      <span className={`text-container ${className}`}>
        {message}
      </span>
    </div>
  )
}
