import React from 'react'
import { ChipSet as NChipSet, Chip as NChip } from '@rmwc/chip'

import '@rmwc/chip/styles'

// --vars

// --components
function Chip ({ ...rest }) {
  return <NChip className='test' {...{ ...rest }} />
}

export function ChipSet ({ ...rest }) {
  return <NChipSet {...{ ...rest }} />
}

// --functions

// --mappings
export default Chip
