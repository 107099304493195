import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Home from '~/components/Home'
import SqLanding from '~/components/SqLanding'
import SqCourseSearchPage from '~/components/sq/CourseSearchPage'
import WedLanding from '~/components/wed-oe/Landing'
import WedOeCourseSearchPage from '~/components/wed-oe/CourseSearchPage'
import StudentsPage from '~/components/StudentsPage'
import AddStudentPage from '~/components/AddStudentPage'
import CartPage from '~/components/CartPage'
import ReceiptPage from '~/components/ReceiptPage'
import TermsOfUsePage from '~/components/TermsOfUsePage'
import PrivacyPage from '~/components/PrivacyPage'
import AccessbilityPage from '~/components/AccessbilityPage'
import Footer from '~/components/Footer'
import RefDataProvider from '~/refdata'
import { LoaderProvider } from './components/Loader'
import Header from '~/components/Header'

// --vars
export const HeaderContext = createContext('')
export const defaultPageTitle = 'Francis Tuttle Technology Center'
const isLive = true

// --components
function App ({ history }) {
  const [headerInfo, setHeaderInfo] = useState({ pageTitle: defaultPageTitle, showSqBanner: false, sqSeasonInfo: null })

  return (
    <LoaderProvider>
      <RefDataProvider>
        <HashRouter>
          <div className='base'>
            <HeaderContext.Provider value={{ headerInfo, setHeaderInfo }}>
              <Header />
              <main>
                <Switch>
                  {
                    isLive
                      ? (
                        <>
                          <Route exact path='/summer-quest' component={SqLanding} />
                          <Route exact path='/lifelong-learning' component={WedLanding} />
                          <Route path='/terms' component={TermsOfUsePage} />
                          <Route path='/privacy' component={PrivacyPage} />
                          <Route path='/accessibility' component={AccessbilityPage} />
                          <Route exact path='/sq-courses' component={SqCourseSearchPage} />
                          <Route exact path='/ll-courses' component={WedOeCourseSearchPage} />
                          <Route exact path='/students' component={StudentsPage} />
                          <Route exact path='/addstudent' component={AddStudentPage} />
                          <Route exact path='/cart' component={CartPage} />
                          <Route exact path='/receipt/:id' component={ReceiptPage} />
                          <Route exact path='/' component={Home} />
                        </>
                      )
                      : <Route component={Home} />
                  }
                </Switch>
              </main>
              <Footer />
            </HeaderContext.Provider>
          </div>
        </HashRouter>
      </RefDataProvider>
    </LoaderProvider>
  )
}

App.propTypes = {
  history: PropTypes.object
}

export default App
