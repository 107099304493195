export function formatPhoneNumber (phoneNumber) {
  if (phoneNumber.length > 0) {
    const maxDigits = 10

    // // remove all dashes (-) and doesn't allow for a phone number with more than 10 digits
    const numberWithoutDashes = phoneNumber.replace(/\D/g, '').substring(0, maxDigits)

    let formattedPhoneNumber = numberWithoutDashes

    if (formattedPhoneNumber.length <= 9) {
      formattedPhoneNumber = formattedPhoneNumber.match(/.{1,3}/g).join('-') // add dash (-) after every 3rd char.
      if (numberWithoutDashes.length === 3 || numberWithoutDashes.length === 6) {
        formattedPhoneNumber = `${formattedPhoneNumber}-`
      }
      return formattedPhoneNumber
    } else {
      formattedPhoneNumber = phoneNumber.replace(/\D/g, '').substring(0, maxDigits).replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      return formattedPhoneNumber
    }
  }
  return ''
}
