export const initialState = {
  students: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'ADD_STUDENT':
      return Object.assign({}, state, {
        students: state.students.concat(action.data)
      })
    case 'REMOVE_STUDENT': {
      const newStudents = state.students.slice(0)
      newStudents.splice(action.data, 1)
      return Object.assign({}, state, {
        students: newStudents
      })
    }
  }

  return state
}
