export const ages = {
  'age-1': {
    label: 'Ages 11-13',
    results: '11-13',
    value: 'ElevenToThirteen',
    min: 11,
    max: 13
  },
  'age-2': {
    label: 'Ages 13-15',
    results: '13-15',
    value: 'ThirteenToFifteen',
    min: 13,
    max: 15
  }
}

export const defaultAge = {
  label: 'Ages 11-15',
  results: '11-15',
  min: 11,
  max: 15
}

export const fromValue = (value, obj, defaultValue = {}) => {
  const key = Object.keys(obj).find(key => obj[key].value === value)
  return key != null ? obj[key] : defaultValue
}
