import moment from 'moment-timezone'

const tz = 'America/Chicago'

export function terseDate (date) {
  return moment(date).tz(tz).format('M/DD')
}

export function formatDurationForDisplay (duration) {
  const startOfDay = moment.tz(tz).startOf('day')
  return startOfDay.add(moment.duration(duration)).format('h:mm a').toUpperCase()
}

export function formatDurationForServer (duration) {
  const startOfDay = moment.tz(tz).startOf('day')
  return startOfDay.add(moment.duration(duration)).format('kk:mm:ss').toUpperCase()
}

export function fullDate (date) {
  return moment(date).tz(tz).format('M/DD/YYYY')
}

export function dateRange (startDate, endDate) {
  return !moment(endDate).isSame(moment(startDate))
    ? moment(startDate).tz(tz).format('M/DD/YYYY') + ' - ' + moment(endDate).tz(tz).format('M/DD/YYYY')
    : fullDate(startDate)
}

export function timeRange (startTime, endTime) {
  if (startTime == null || endTime == null) {
    return '---'
  }
  return formatDurationForDisplay(startTime) + ' - ' + formatDurationForDisplay(endTime)
}

export function durationToMoment (duration) {
  const startOfDay = moment.tz(tz).startOf('day')
  return startOfDay.add(moment.duration(duration))
}

export function ageToday (birthDate) {
  return moment.tz(tz).diff(moment(birthDate).tz(tz), 'years')
}
