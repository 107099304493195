import React from 'react'
import { TextField as NTextField } from '@rmwc/textfield'

import '@rmwc/textfield/styles'
import './TextField.scss'

// --vars

// --components
function TextField ({ textarea = false, className = '', ...rest }) {
  return (
    <NTextField
      outlined
      className={`ntextfield ${className}`}
      {...{ textarea, ...rest }}
      style={textarea ? { width: '100%' } : undefined}
    />
  )
}

// --functions

// --mappings
export default TextField
